/*!
   * Bootstrap  v5.2.2 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&amp;display=swap");
@import url("../cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("../cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}
h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}
h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}
h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small,
.small {
  font-size: 0.875em;
}
mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: rgba(0, 0, 0, 0);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: rgba(0, 0, 0, 0);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table-group-divider {
  border-top: 2px solid currentcolor;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}
.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280,0,0,0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation
  > :nth-last-child(n
    + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}
.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated .input-group > .form-control:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}
.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated .input-group > .form-control:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}
.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}
.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}
.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}
.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}
.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}
.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}
.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}
.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}
.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}
.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}
.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}
.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}
.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}
.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}
.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}
.btn-lg,
.btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}
.btn-sm,
.btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}
.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280,0,0,0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255,255,255,0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}
.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}
.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}
.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}
.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}
.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}
.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(
    --bs-breadcrumb-divider,
    "https://www.pixelwibes.com/"
  ); /* rtl: var(--bs-breadcrumb-divider, "/") */
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled,
.disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}
.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}
.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}
.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}
.alert-primary {
  --bs-alert-color: #084298;
  --bs-alert-bg: #cfe2ff;
  --bs-alert-border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}
.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}
.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}
.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}
.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}
.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}
.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}
.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}
.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}
.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}
.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}
.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}
.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}
.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}
.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}
.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}
.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}
.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  border-top-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}
.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y))
    calc(-0.5 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}
.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}
.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}
.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height)
    calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}
.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}
.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-header-bg);
}
.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0
    calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg); /* rtl:ignore */
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}
.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas-sm,
.offcanvas-md,
.offcanvas-lg,
.offcanvas-xl,
.offcanvas-xxl,
.offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}
@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}
.link-primary {
  color: #0d6efd !important;
}
.link-primary:hover,
.link-primary:focus {
  color: #0a58ca !important;
}
.link-secondary {
  color: #6c757d !important;
}
.link-secondary:hover,
.link-secondary:focus {
  color: #565e64 !important;
}
.link-success {
  color: #198754 !important;
}
.link-success:hover,
.link-success:focus {
  color: #146c43 !important;
}
.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover,
.link-info:focus {
  color: #3dd5f3 !important;
}
.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover,
.link-warning:focus {
  color: #ffcd39 !important;
}
.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover,
.link-danger:focus {
  color: #b02a37 !important;
}
.link-light {
  color: #f8f9fa !important;
}
.link-light:hover,
.link-light:focus {
  color: #f9fafb !important;
}
.link-dark {
  color: #212529 !important;
}
.link-dark:hover,
.link-dark:focus {
  color: #1a1e21 !important;
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}
.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}
.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}
.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}
.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}
.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
.border-1 {
  --bs-border-width: 1px;
}
.border-2 {
  --bs-border-width: 2px;
}
.border-3 {
  --bs-border-width: 3px;
}
.border-4 {
  --bs-border-width: 4px;
}
.border-5 {
  --bs-border-width: 5px;
}
.border-opacity-10 {
  --bs-border-opacity: 0.1;
}
.border-opacity-25 {
  --bs-border-opacity: 0.25;
}
.border-opacity-50 {
  --bs-border-opacity: 0.5;
}
.border-opacity-75 {
  --bs-border-opacity: 0.75;
}
.border-opacity-100 {
  --bs-border-opacity: 1;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.fs-5 {
  font-size: 1.25rem !important;
}
.fs-6 {
  font-size: 1rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  user-select: all !important;
}
.user-select-auto {
  user-select: auto !important;
}
.user-select-none {
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@font-face {
  font-family: "IcoFont";
  src: url("../fonts/icofont/fonts/icofont.eot");
  src: url("../fonts/icofont/fonts/icofontd41d.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/icofont/fonts/icofont.woff") format("woff"),
    url("../fonts/icofont/fonts/icofont.ttf") format("truetype"),
    url("../fonts/icofont/fonts/icofont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="icofont-"],
[class*=" icofont-"] {
  font-family: "IcoFont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  line-height: 1;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.icofont-angry-monster:before {
  content: "\e800";
}
.icofont-bathtub:before {
  content: "\e801";
}
.icofont-bird-wings:before {
  content: "\e802";
}
.icofont-bow:before {
  content: "\e803";
}
.icofont-castle:before {
  content: "\e804";
}
.icofont-circuit:before {
  content: "\e805";
}
.icofont-crown-king:before {
  content: "\e806";
}
.icofont-crown-queen:before {
  content: "\e807";
}
.icofont-dart:before {
  content: "\e808";
}
.icofont-disability-race:before {
  content: "\e809";
}
.icofont-diving-goggle:before {
  content: "\e80a";
}
.icofont-eye-open:before {
  content: "\e80b";
}
.icofont-flora-flower:before {
  content: "\e80c";
}
.icofont-flora:before {
  content: "\e80d";
}
.icofont-gift-box:before {
  content: "\e80e";
}
.icofont-halloween-pumpkin:before {
  content: "\e80f";
}
.icofont-hand-power:before {
  content: "\e810";
}
.icofont-hand-thunder:before {
  content: "\e811";
}
.icofont-king-monster:before {
  content: "\e812";
}
.icofont-love:before {
  content: "\e813";
}
.icofont-magician-hat:before {
  content: "\e814";
}
.icofont-native-american:before {
  content: "\e815";
}
.icofont-owl-look:before {
  content: "\e816";
}
.icofont-phoenix:before {
  content: "\e817";
}
.icofont-robot-face:before {
  content: "\e818";
}
.icofont-sand-clock:before {
  content: "\e819";
}
.icofont-shield-alt:before {
  content: "\e81a";
}
.icofont-ship-wheel:before {
  content: "\e81b";
}
.icofont-skull-danger:before {
  content: "\e81c";
}
.icofont-skull-face:before {
  content: "\e81d";
}
.icofont-snowmobile:before {
  content: "\e81e";
}
.icofont-space-shuttle:before {
  content: "\e81f";
}
.icofont-star-my-Task:before {
  content: "\e820";
}
.icofont-swirl:before {
  content: "\e821";
}
.icofont-tattoo-wing:before {
  content: "\e822";
}
.icofont-throne:before {
  content: "\e823";
}
.icofont-tree-alt:before {
  content: "\e824";
}
.icofont-triangle:before {
  content: "\e825";
}
.icofont-unity-hand:before {
  content: "\e826";
}
.icofont-weed:before {
  content: "\e827";
}
.icofont-woman-bird:before {
  content: "\e828";
}
.icofont-bat:before {
  content: "\e829";
}
.icofont-bear-face:before {
  content: "\e82a";
}
.icofont-bear-tracks:before {
  content: "\e82b";
}
.icofont-bear:before {
  content: "\e82c";
}
.icofont-bird-alt:before {
  content: "\e82d";
}
.icofont-bird-flying:before {
  content: "\e82e";
}
.icofont-bird:before {
  content: "\e82f";
}
.icofont-birds:before {
  content: "\e830";
}
.icofont-bone:before {
  content: "\e831";
}
.icofont-bull:before {
  content: "\e832";
}
.icofont-butterfly-alt:before {
  content: "\e833";
}
.icofont-butterfly:before {
  content: "\e834";
}
.icofont-camel-alt:before {
  content: "\e835";
}
.icofont-camel-head:before {
  content: "\e836";
}
.icofont-camel:before {
  content: "\e837";
}
.icofont-cat-alt-1:before {
  content: "\e838";
}
.icofont-cat-alt-2:before {
  content: "\e839";
}
.icofont-cat-alt-3:before {
  content: "\e83a";
}
.icofont-cat-dog:before {
  content: "\e83b";
}
.icofont-cat-face:before {
  content: "\e83c";
}
.icofont-cat:before {
  content: "\e83d";
}
.icofont-cow-head:before {
  content: "\e83e";
}
.icofont-cow:before {
  content: "\e83f";
}
.icofont-crab:before {
  content: "\e840";
}
.icofont-crocodile:before {
  content: "\e841";
}
.icofont-deer-head:before {
  content: "\e842";
}
.icofont-dog-alt:before {
  content: "\e843";
}
.icofont-dog-barking:before {
  content: "\e844";
}
.icofont-dog:before {
  content: "\e845";
}
.icofont-dolphin:before {
  content: "\e846";
}
.icofont-duck-tracks:before {
  content: "\e847";
}
.icofont-eagle-head:before {
  content: "\e848";
}
.icofont-eaten-fish:before {
  content: "\e849";
}
.icofont-elephant-alt:before {
  content: "\e84a";
}
.icofont-elephant-head-alt:before {
  content: "\e84b";
}
.icofont-elephant-head:before {
  content: "\e84c";
}
.icofont-elephant:before {
  content: "\e84d";
}
.icofont-elk:before {
  content: "\e84e";
}
.icofont-fish-1:before {
  content: "\e84f";
}
.icofont-fish-2:before {
  content: "\e850";
}
.icofont-fish-3:before {
  content: "\e851";
}
.icofont-fish-4:before {
  content: "\e852";
}
.icofont-fish-5:before {
  content: "\e853";
}
.icofont-fish:before {
  content: "\e854";
}
.icofont-fox-alt:before {
  content: "\e855";
}
.icofont-fox:before {
  content: "\e856";
}
.icofont-frog-tracks:before {
  content: "\e857";
}
.icofont-frog:before {
  content: "\e858";
}
.icofont-froggy:before {
  content: "\e859";
}
.icofont-giraffe-head-1:before {
  content: "\e85a";
}
.icofont-giraffe-head-2:before {
  content: "\e85b";
}
.icofont-giraffe-head:before {
  content: "\e85c";
}
.icofont-giraffe:before {
  content: "\e85d";
}
.icofont-goat-head:before {
  content: "\e85e";
}
.icofont-gorilla:before {
  content: "\e85f";
}
.icofont-hen-tracks:before {
  content: "\e860";
}
.icofont-horse-head-1:before {
  content: "\e861";
}
.icofont-horse-head-2:before {
  content: "\e862";
}
.icofont-horse-head:before {
  content: "\e863";
}
.icofont-horse-tracks:before {
  content: "\e864";
}
.icofont-jellyfish:before {
  content: "\e865";
}
.icofont-kangaroo:before {
  content: "\e866";
}
.icofont-lemur:before {
  content: "\e867";
}
.icofont-lion-head-1:before {
  content: "\e868";
}
.icofont-lion-head-2:before {
  content: "\e869";
}
.icofont-lion-head:before {
  content: "\e86a";
}
.icofont-lion:before {
  content: "\e86b";
}
.icofont-monkey-2:before {
  content: "\e86c";
}
.icofont-monkey-3:before {
  content: "\e86d";
}
.icofont-monkey-face:before {
  content: "\e86e";
}
.icofont-monkey:before {
  content: "\e86f";
}
.icofont-octopus-alt:before {
  content: "\e870";
}
.icofont-octopus:before {
  content: "\e871";
}
.icofont-owl:before {
  content: "\e872";
}
.icofont-panda-face:before {
  content: "\e873";
}
.icofont-panda:before {
  content: "\e874";
}
.icofont-panther:before {
  content: "\e875";
}
.icofont-parrot-lip:before {
  content: "\e876";
}
.icofont-parrot:before {
  content: "\e877";
}
.icofont-paw:before {
  content: "\e878";
}
.icofont-pelican:before {
  content: "\e879";
}
.icofont-penguin:before {
  content: "\e87a";
}
.icofont-pig-face:before {
  content: "\e87b";
}
.icofont-pig:before {
  content: "\e87c";
}
.icofont-pigeon-1:before {
  content: "\e87d";
}
.icofont-pigeon-2:before {
  content: "\e87e";
}
.icofont-pigeon:before {
  content: "\e87f";
}
.icofont-rabbit:before {
  content: "\e880";
}
.icofont-rat:before {
  content: "\e881";
}
.icofont-rhino-head:before {
  content: "\e882";
}
.icofont-rhino:before {
  content: "\e883";
}
.icofont-rooster:before {
  content: "\e884";
}
.icofont-seahorse:before {
  content: "\e885";
}
.icofont-seal:before {
  content: "\e886";
}
.icofont-shrimp-alt:before {
  content: "\e887";
}
.icofont-shrimp:before {
  content: "\e888";
}
.icofont-snail-1:before {
  content: "\e889";
}
.icofont-snail-2:before {
  content: "\e88a";
}
.icofont-snail-3:before {
  content: "\e88b";
}
.icofont-snail:before {
  content: "\e88c";
}
.icofont-snake:before {
  content: "\e88d";
}
.icofont-squid:before {
  content: "\e88e";
}
.icofont-squirrel:before {
  content: "\e88f";
}
.icofont-tiger-face:before {
  content: "\e890";
}
.icofont-tiger:before {
  content: "\e891";
}
.icofont-turtle:before {
  content: "\e892";
}
.icofont-whale:before {
  content: "\e893";
}
.icofont-woodpecker:before {
  content: "\e894";
}
.icofont-zebra:before {
  content: "\e895";
}
.icofont-brand-acer:before {
  content: "\e896";
}
.icofont-brand-adidas:before {
  content: "\e897";
}
.icofont-brand-adobe:before {
  content: "\e898";
}
.icofont-brand-air-new-zealand:before {
  content: "\e899";
}
.icofont-brand-airbnb:before {
  content: "\e89a";
}
.icofont-brand-aircell:before {
  content: "\e89b";
}
.icofont-brand-airtel:before {
  content: "\e89c";
}
.icofont-brand-alcatel:before {
  content: "\e89d";
}
.icofont-brand-alibaba:before {
  content: "\e89e";
}
.icofont-brand-aliexpress:before {
  content: "\e89f";
}
.icofont-brand-alipay:before {
  content: "\e8a0";
}
.icofont-brand-amazon:before {
  content: "\e8a1";
}
.icofont-brand-amd:before {
  content: "\e8a2";
}
.icofont-brand-american-airlines:before {
  content: "\e8a3";
}
.icofont-brand-android-robot:before {
  content: "\e8a4";
}
.icofont-brand-android:before {
  content: "\e8a5";
}
.icofont-brand-aol:before {
  content: "\e8a6";
}
.icofont-brand-apple:before {
  content: "\e8a7";
}
.icofont-brand-appstore:before {
  content: "\e8a8";
}
.icofont-brand-asus:before {
  content: "\e8a9";
}
.icofont-brand-ati:before {
  content: "\e8aa";
}
.icofont-brand-att:before {
  content: "\e8ab";
}
.icofont-brand-audi:before {
  content: "\e8ac";
}
.icofont-brand-axiata:before {
  content: "\e8ad";
}
.icofont-brand-bada:before {
  content: "\e8ae";
}
.icofont-brand-bbc:before {
  content: "\e8af";
}
.icofont-brand-bing:before {
  content: "\e8b0";
}
.icofont-brand-blackberry:before {
  content: "\e8b1";
}
.icofont-brand-bmw:before {
  content: "\e8b2";
}
.icofont-brand-box:before {
  content: "\e8b3";
}
.icofont-brand-burger-king:before {
  content: "\e8b4";
}
.icofont-brand-business-insider:before {
  content: "\e8b5";
}
.icofont-brand-buzzfeed:before {
  content: "\e8b6";
}
.icofont-brand-cannon:before {
  content: "\e8b7";
}
.icofont-brand-casio:before {
  content: "\e8b8";
}
.icofont-brand-china-mobile:before {
  content: "\e8b9";
}
.icofont-brand-china-telecom:before {
  content: "\e8ba";
}
.icofont-brand-china-unicom:before {
  content: "\e8bb";
}
.icofont-brand-cisco:before {
  content: "\e8bc";
}
.icofont-brand-citibank:before {
  content: "\e8bd";
}
.icofont-brand-cnet:before {
  content: "\e8be";
}
.icofont-brand-cnn:before {
  content: "\e8bf";
}
.icofont-brand-cocal-cola:before {
  content: "\e8c0";
}
.icofont-brand-compaq:before {
  content: "\e8c1";
}
.icofont-brand-debian:before {
  content: "\e8c2";
}
.icofont-brand-delicious:before {
  content: "\e8c3";
}
.icofont-brand-dell:before {
  content: "\e8c4";
}
.icofont-brand-designbump:before {
  content: "\e8c5";
}
.icofont-brand-designfloat:before {
  content: "\e8c6";
}
.icofont-brand-disney:before {
  content: "\e8c7";
}
.icofont-brand-dodge:before {
  content: "\e8c8";
}
.icofont-brand-dove:before {
  content: "\e8c9";
}
.icofont-brand-drupal:before {
  content: "\e8ca";
}
.icofont-brand-ebay:before {
  content: "\e8cb";
}
.icofont-brand-eleven:before {
  content: "\e8cc";
}
.icofont-brand-emirates:before {
  content: "\e8cd";
}
.icofont-brand-espn:before {
  content: "\e8ce";
}
.icofont-brand-etihad-airways:before {
  content: "\e8cf";
}
.icofont-brand-etisalat:before {
  content: "\e8d0";
}
.icofont-brand-etsy:before {
  content: "\e8d1";
}
.icofont-brand-fastrack:before {
  content: "\e8d2";
}
.icofont-brand-fedex:before {
  content: "\e8d3";
}
.icofont-brand-ferrari:before {
  content: "\e8d4";
}
.icofont-brand-fitbit:before {
  content: "\e8d5";
}
.icofont-brand-flikr:before {
  content: "\e8d6";
}
.icofont-brand-forbes:before {
  content: "\e8d7";
}
.icofont-brand-foursquare:before {
  content: "\e8d8";
}
.icofont-brand-foxconn:before {
  content: "\e8d9";
}
.icofont-brand-fujitsu:before {
  content: "\e8da";
}
.icofont-brand-general-electric:before {
  content: "\e8db";
}
.icofont-brand-gillette:before {
  content: "\e8dc";
}
.icofont-brand-gizmodo:before {
  content: "\e8dd";
}
.icofont-brand-gnome:before {
  content: "\e8de";
}
.icofont-brand-google:before {
  content: "\e8df";
}
.icofont-brand-gopro:before {
  content: "\e8e0";
}
.icofont-brand-gucci:before {
  content: "\e8e1";
}
.icofont-brand-hallmark:before {
  content: "\e8e2";
}
.icofont-brand-hi5:before {
  content: "\e8e3";
}
.icofont-brand-honda:before {
  content: "\e8e4";
}
.icofont-brand-hp:before {
  content: "\e8e5";
}
.icofont-brand-hsbc:before {
  content: "\e8e6";
}
.icofont-brand-htc:before {
  content: "\e8e7";
}
.icofont-brand-huawei:before {
  content: "\e8e8";
}
.icofont-brand-hulu:before {
  content: "\e8e9";
}
.icofont-brand-hyundai:before {
  content: "\e8ea";
}
.icofont-brand-ibm:before {
  content: "\e8eb";
}
.icofont-brand-icofont:before {
  content: "\e8ec";
}
.icofont-brand-icq:before {
  content: "\e8ed";
}
.icofont-brand-ikea:before {
  content: "\e8ee";
}
.icofont-brand-imdb:before {
  content: "\e8ef";
}
.icofont-brand-indiegogo:before {
  content: "\e8f0";
}
.icofont-brand-intel:before {
  content: "\e8f1";
}
.icofont-brand-ipair:before {
  content: "\e8f2";
}
.icofont-brand-jaguar:before {
  content: "\e8f3";
}
.icofont-brand-java:before {
  content: "\e8f4";
}
.icofont-brand-joomla:before {
  content: "\e8f5";
}
.icofont-brand-kickstarter:before {
  content: "\e8f6";
}
.icofont-brand-kik:before {
  content: "\e8f7";
}
.icofont-brand-lastfm:before {
  content: "\e8f8";
}
.icofont-brand-lego:before {
  content: "\e8f9";
}
.icofont-brand-lenovo:before {
  content: "\e8fa";
}
.icofont-brand-levis:before {
  content: "\e8fb";
}
.icofont-brand-lexus:before {
  content: "\e8fc";
}
.icofont-brand-lg:before {
  content: "\e8fd";
}
.icofont-brand-life-hacker:before {
  content: "\e8fe";
}
.icofont-brand-linux-mint:before {
  content: "\e8ff";
}
.icofont-brand-linux:before {
  content: "\e900";
}
.icofont-brand-lionix:before {
  content: "\e901";
}
.icofont-brand-loreal:before {
  content: "\e902";
}
.icofont-brand-louis-vuitton:before {
  content: "\e903";
}
.icofont-brand-mac-os:before {
  content: "\e904";
}
.icofont-brand-marvel-app:before {
  content: "\e905";
}
.icofont-brand-mashable:before {
  content: "\e906";
}
.icofont-brand-mazda:before {
  content: "\e907";
}
.icofont-brand-mcdonals:before {
  content: "\e908";
}
.icofont-brand-mercedes:before {
  content: "\e909";
}
.icofont-brand-micromax:before {
  content: "\e90a";
}
.icofont-brand-microsoft:before {
  content: "\e90b";
}
.icofont-brand-mobileme:before {
  content: "\e90c";
}
.icofont-brand-mobily:before {
  content: "\e90d";
}
.icofont-brand-motorola:before {
  content: "\e90e";
}
.icofont-brand-msi:before {
  content: "\e90f";
}
.icofont-brand-mts:before {
  content: "\e910";
}
.icofont-brand-myspace:before {
  content: "\e911";
}
.icofont-brand-mytv:before {
  content: "\e912";
}
.icofont-brand-nasa:before {
  content: "\e913";
}
.icofont-brand-natgeo:before {
  content: "\e914";
}
.icofont-brand-nbc:before {
  content: "\e915";
}
.icofont-brand-nescafe:before {
  content: "\e916";
}
.icofont-brand-nestle:before {
  content: "\e917";
}
.icofont-brand-netflix:before {
  content: "\e918";
}
.icofont-brand-nexus:before {
  content: "\e919";
}
.icofont-brand-nike:before {
  content: "\e91a";
}
.icofont-brand-nokia:before {
  content: "\e91b";
}
.icofont-brand-nvidia:before {
  content: "\e91c";
}
.icofont-brand-omega:before {
  content: "\e91d";
}
.icofont-brand-opensuse:before {
  content: "\e91e";
}
.icofont-brand-oracle:before {
  content: "\e91f";
}
.icofont-brand-panasonic:before {
  content: "\e920";
}
.icofont-brand-paypal:before {
  content: "\e921";
}
.icofont-brand-pepsi:before {
  content: "\e922";
}
.icofont-brand-philips:before {
  content: "\e923";
}
.icofont-brand-pizza-hut:before {
  content: "\e924";
}
.icofont-brand-playstation:before {
  content: "\e925";
}
.icofont-brand-puma:before {
  content: "\e926";
}
.icofont-brand-qatar-air:before {
  content: "\e927";
}
.icofont-brand-qvc:before {
  content: "\e928";
}
.icofont-brand-readernaut:before {
  content: "\e929";
}
.icofont-brand-redbull:before {
  content: "\e92a";
}
.icofont-brand-reebok:before {
  content: "\e92b";
}
.icofont-brand-reuters:before {
  content: "\e92c";
}
.icofont-brand-samsung:before {
  content: "\e92d";
}
.icofont-brand-sap:before {
  content: "\e92e";
}
.icofont-brand-saudia-airlines:before {
  content: "\e92f";
}
.icofont-brand-scribd:before {
  content: "\e930";
}
.icofont-brand-shell:before {
  content: "\e931";
}
.icofont-brand-siemens:before {
  content: "\e932";
}
.icofont-brand-sk-telecom:before {
  content: "\e933";
}
.icofont-brand-slideshare:before {
  content: "\e934";
}
.icofont-brand-smashing-magazine:before {
  content: "\e935";
}
.icofont-brand-snapchat:before {
  content: "\e936";
}
.icofont-brand-sony-ericsson:before {
  content: "\e937";
}
.icofont-brand-sony:before {
  content: "\e938";
}
.icofont-brand-soundcloud:before {
  content: "\e939";
}
.icofont-brand-sprint:before {
  content: "\e93a";
}
.icofont-brand-squidoo:before {
  content: "\e93b";
}
.icofont-brand-starbucks:before {
  content: "\e93c";
}
.icofont-brand-stc:before {
  content: "\e93d";
}
.icofont-brand-steam:before {
  content: "\e93e";
}
.icofont-brand-suzuki:before {
  content: "\e93f";
}
.icofont-brand-symbian:before {
  content: "\e940";
}
.icofont-brand-t-mobile:before {
  content: "\e941";
}
.icofont-brand-tango:before {
  content: "\e942";
}
.icofont-brand-target:before {
  content: "\e943";
}
.icofont-brand-tata-indicom:before {
  content: "\e944";
}
.icofont-brand-techcrunch:before {
  content: "\e945";
}
.icofont-brand-telenor:before {
  content: "\e946";
}
.icofont-brand-teliasonera:before {
  content: "\e947";
}
.icofont-brand-tesla:before {
  content: "\e948";
}
.icofont-brand-the-verge:before {
  content: "\e949";
}
.icofont-brand-thenextweb:before {
  content: "\e94a";
}
.icofont-brand-toshiba:before {
  content: "\e94b";
}
.icofont-brand-toyota:before {
  content: "\e94c";
}
.icofont-brand-tribenet:before {
  content: "\e94d";
}
.icofont-brand-ubuntu:before {
  content: "\e94e";
}
.icofont-brand-unilever:before {
  content: "\e94f";
}
.icofont-brand-vaio:before {
  content: "\e950";
}
.icofont-brand-verizon:before {
  content: "\e951";
}
.icofont-brand-viber:before {
  content: "\e952";
}
.icofont-brand-vodafone:before {
  content: "\e953";
}
.icofont-brand-volkswagen:before {
  content: "\e954";
}
.icofont-brand-walmart:before {
  content: "\e955";
}
.icofont-brand-warnerbros:before {
  content: "\e956";
}
.icofont-brand-whatsapp:before {
  content: "\e957";
}
.icofont-brand-wikipedia:before {
  content: "\e958";
}
.icofont-brand-windows:before {
  content: "\e959";
}
.icofont-brand-wire:before {
  content: "\e95a";
}
.icofont-brand-wordpress:before {
  content: "\e95b";
}
.icofont-brand-xiaomi:before {
  content: "\e95c";
}
.icofont-brand-yahoobuzz:before {
  content: "\e95d";
}
.icofont-brand-yamaha:before {
  content: "\e95e";
}
.icofont-brand-youtube:before {
  content: "\e95f";
}
.icofont-brand-zain:before {
  content: "\e960";
}
.icofont-bank-alt:before {
  content: "\e961";
}
.icofont-bank:before {
  content: "\e962";
}
.icofont-barcode:before {
  content: "\e963";
}
.icofont-bill-alt:before {
  content: "\e964";
}
.icofont-billboard:before {
  content: "\e965";
}
.icofont-briefcase-1:before {
  content: "\e966";
}
.icofont-briefcase-2:before {
  content: "\e967";
}
.icofont-businessman:before {
  content: "\e968";
}
.icofont-businesswoman:before {
  content: "\e969";
}
.icofont-chair:before {
  content: "\e96a";
}
.icofont-coins:before {
  content: "\e96b";
}
.icofont-company:before {
  content: "\e96c";
}
.icofont-contact-add:before {
  content: "\e96d";
}
.icofont-files-stack:before {
  content: "\e96e";
}
.icofont-handshake-deal:before {
  content: "\e96f";
}
.icofont-id-card:before {
  content: "\e970";
}
.icofont-meeting-add:before {
  content: "\e971";
}
.icofont-money-bag:before {
  content: "\e972";
}
.icofont-pie-chart:before {
  content: "\e973";
}
.icofont-presentation-alt:before {
  content: "\e974";
}
.icofont-presentation:before {
  content: "\e975";
}
.icofont-stamp:before {
  content: "\e976";
}
.icofont-stock-mobile:before {
  content: "\e977";
}
.icofont-chart-arrows-axis:before {
  content: "\e978";
}
.icofont-chart-bar-graph:before {
  content: "\e979";
}
.icofont-chart-flow-1:before {
  content: "\e97a";
}
.icofont-chart-flow-2:before {
  content: "\e97b";
}
.icofont-chart-flow:before {
  content: "\e97c";
}
.icofont-chart-growth:before {
  content: "\e97d";
}
.icofont-chart-histogram-alt:before {
  content: "\e97e";
}
.icofont-chart-histogram:before {
  content: "\e97f";
}
.icofont-chart-line-alt:before {
  content: "\e980";
}
.icofont-chart-line:before {
  content: "\e981";
}
.icofont-chart-pie-alt:before {
  content: "\e982";
}
.icofont-chart-pie:before {
  content: "\e983";
}
.icofont-chart-radar-graph:before {
  content: "\e984";
}
.icofont-architecture-alt:before {
  content: "\e985";
}
.icofont-architecture:before {
  content: "\e986";
}
.icofont-barricade:before {
  content: "\e987";
}
.icofont-bolt:before {
  content: "\e988";
}
.icofont-bricks:before {
  content: "\e989";
}
.icofont-building-alt:before {
  content: "\e98a";
}
.icofont-bull-dozer:before {
  content: "\e98b";
}
.icofont-calculations:before {
  content: "\e98c";
}
.icofont-cement-mix:before {
  content: "\e98d";
}
.icofont-cement-mixer:before {
  content: "\e98e";
}
.icofont-concrete-mixer:before {
  content: "\e98f";
}
.icofont-danger-zone:before {
  content: "\e990";
}
.icofont-drill:before {
  content: "\e991";
}
.icofont-eco-energy:before {
  content: "\e992";
}
.icofont-eco-environmen:before {
  content: "\e993";
}
.icofont-energy-air:before {
  content: "\e994";
}
.icofont-energy-oil:before {
  content: "\e995";
}
.icofont-energy-savings:before {
  content: "\e996";
}
.icofont-energy-solar:before {
  content: "\e997";
}
.icofont-energy-water:before {
  content: "\e998";
}
.icofont-engineer:before {
  content: "\e999";
}
.icofont-fire-extinguisher-alt:before {
  content: "\e99a";
}
.icofont-fire-extinguisher:before {
  content: "\e99b";
}
.icofont-fix-tools:before {
  content: "\e99c";
}
.icofont-fork-lift:before {
  content: "\e99d";
}
.icofont-glue-oil:before {
  content: "\e99e";
}
.icofont-hammer-alt:before {
  content: "\e99f";
}
.icofont-hammer:before {
  content: "\e9a0";
}
.icofont-help-robot:before {
  content: "\e9a1";
}
.icofont-industries-1:before {
  content: "\e9a2";
}
.icofont-industries-2:before {
  content: "\e9a3";
}
.icofont-industries-3:before {
  content: "\e9a4";
}
.icofont-industries-4:before {
  content: "\e9a5";
}
.icofont-industries-5:before {
  content: "\e9a6";
}
.icofont-industries:before {
  content: "\e9a7";
}
.icofont-labour:before {
  content: "\e9a8";
}
.icofont-mining:before {
  content: "\e9a9";
}
.icofont-paint-brush:before {
  content: "\e9aa";
}
.icofont-pollution:before {
  content: "\e9ab";
}
.icofont-power-zone:before {
  content: "\e9ac";
}
.icofont-radio-active:before {
  content: "\e9ad";
}
.icofont-recycle-alt:before {
  content: "\e9ae";
}
.icofont-recycling-man:before {
  content: "\e9af";
}
.icofont-safety-hat-light:before {
  content: "\e9b0";
}
.icofont-safety-hat:before {
  content: "\e9b1";
}
.icofont-saw:before {
  content: "\e9b2";
}
.icofont-screw-driver:before {
  content: "\e9b3";
}
.icofont-tools-1:before {
  content: "\e9b4";
}
.icofont-tools-bag:before {
  content: "\e9b5";
}
.icofont-tow-truck:before {
  content: "\e9b6";
}
.icofont-trolley:before {
  content: "\e9b7";
}
.icofont-trowel:before {
  content: "\e9b8";
}
.icofont-under-construction-alt:before {
  content: "\e9b9";
}
.icofont-under-construction:before {
  content: "\e9ba";
}
.icofont-vehicle-cement:before {
  content: "\e9bb";
}
.icofont-vehicle-crane:before {
  content: "\e9bc";
}
.icofont-vehicle-delivery-van:before {
  content: "\e9bd";
}
.icofont-vehicle-dozer:before {
  content: "\e9be";
}
.icofont-vehicle-excavator:before {
  content: "\e9bf";
}
.icofont-vehicle-trucktor:before {
  content: "\e9c0";
}
.icofont-vehicle-wrecking:before {
  content: "\e9c1";
}
.icofont-worker:before {
  content: "\e9c2";
}
.icofont-workers-group:before {
  content: "\e9c3";
}
.icofont-wrench:before {
  content: "\e9c4";
}
.icofont-afghani-false:before {
  content: "\e9c5";
}
.icofont-afghani-minus:before {
  content: "\e9c6";
}
.icofont-afghani-plus:before {
  content: "\e9c7";
}
.icofont-afghani-true:before {
  content: "\e9c8";
}
.icofont-afghani:before {
  content: "\e9c9";
}
.icofont-baht-false:before {
  content: "\e9ca";
}
.icofont-baht-minus:before {
  content: "\e9cb";
}
.icofont-baht-plus:before {
  content: "\e9cc";
}
.icofont-baht-true:before {
  content: "\e9cd";
}
.icofont-baht:before {
  content: "\e9ce";
}
.icofont-bitcoin-false:before {
  content: "\e9cf";
}
.icofont-bitcoin-minus:before {
  content: "\e9d0";
}
.icofont-bitcoin-plus:before {
  content: "\e9d1";
}
.icofont-bitcoin-true:before {
  content: "\e9d2";
}
.icofont-bitcoin:before {
  content: "\e9d3";
}
.icofont-dollar-flase:before {
  content: "\e9d4";
}
.icofont-dollar-minus:before {
  content: "\e9d5";
}
.icofont-dollar-plus:before {
  content: "\e9d6";
}
.icofont-dollar-true:before {
  content: "\e9d7";
}
.icofont-dollar:before {
  content: "\e9d8";
}
.icofont-dong-false:before {
  content: "\e9d9";
}
.icofont-dong-minus:before {
  content: "\e9da";
}
.icofont-dong-plus:before {
  content: "\e9db";
}
.icofont-dong-true:before {
  content: "\e9dc";
}
.icofont-dong:before {
  content: "\e9dd";
}
.icofont-euro-false:before {
  content: "\e9de";
}
.icofont-euro-minus:before {
  content: "\e9df";
}
.icofont-euro-plus:before {
  content: "\e9e0";
}
.icofont-euro-true:before {
  content: "\e9e1";
}
.icofont-euro:before {
  content: "\e9e2";
}
.icofont-frank-false:before {
  content: "\e9e3";
}
.icofont-frank-minus:before {
  content: "\e9e4";
}
.icofont-frank-plus:before {
  content: "\e9e5";
}
.icofont-frank-true:before {
  content: "\e9e6";
}
.icofont-frank:before {
  content: "\e9e7";
}
.icofont-hryvnia-false:before {
  content: "\e9e8";
}
.icofont-hryvnia-minus:before {
  content: "\e9e9";
}
.icofont-hryvnia-plus:before {
  content: "\e9ea";
}
.icofont-hryvnia-true:before {
  content: "\e9eb";
}
.icofont-hryvnia:before {
  content: "\e9ec";
}
.icofont-lira-false:before {
  content: "\e9ed";
}
.icofont-lira-minus:before {
  content: "\e9ee";
}
.icofont-lira-plus:before {
  content: "\e9ef";
}
.icofont-lira-true:before {
  content: "\e9f0";
}
.icofont-lira:before {
  content: "\e9f1";
}
.icofont-peseta-false:before {
  content: "\e9f2";
}
.icofont-peseta-minus:before {
  content: "\e9f3";
}
.icofont-peseta-plus:before {
  content: "\e9f4";
}
.icofont-peseta-true:before {
  content: "\e9f5";
}
.icofont-peseta:before {
  content: "\e9f6";
}
.icofont-peso-false:before {
  content: "\e9f7";
}
.icofont-peso-minus:before {
  content: "\e9f8";
}
.icofont-peso-plus:before {
  content: "\e9f9";
}
.icofont-peso-true:before {
  content: "\e9fa";
}
.icofont-peso:before {
  content: "\e9fb";
}
.icofont-pound-false:before {
  content: "\e9fc";
}
.icofont-pound-minus:before {
  content: "\e9fd";
}
.icofont-pound-plus:before {
  content: "\e9fe";
}
.icofont-pound-true:before {
  content: "\e9ff";
}
.icofont-pound:before {
  content: "\ea00";
}
.icofont-renminbi-false:before {
  content: "\ea01";
}
.icofont-renminbi-minus:before {
  content: "\ea02";
}
.icofont-renminbi-plus:before {
  content: "\ea03";
}
.icofont-renminbi-true:before {
  content: "\ea04";
}
.icofont-renminbi:before {
  content: "\ea05";
}
.icofont-riyal-false:before {
  content: "\ea06";
}
.icofont-riyal-minus:before {
  content: "\ea07";
}
.icofont-riyal-plus:before {
  content: "\ea08";
}
.icofont-riyal-true:before {
  content: "\ea09";
}
.icofont-riyal:before {
  content: "\ea0a";
}
.icofont-rouble-false:before {
  content: "\ea0b";
}
.icofont-rouble-minus:before {
  content: "\ea0c";
}
.icofont-rouble-plus:before {
  content: "\ea0d";
}
.icofont-rouble-true:before {
  content: "\ea0e";
}
.icofont-rouble:before {
  content: "\ea0f";
}
.icofont-rupee-false:before {
  content: "\ea10";
}
.icofont-rupee-minus:before {
  content: "\ea11";
}
.icofont-rupee-plus:before {
  content: "\ea12";
}
.icofont-rupee-true:before {
  content: "\ea13";
}
.icofont-rupee:before {
  content: "\ea14";
}
.icofont-taka-false:before {
  content: "\ea15";
}
.icofont-taka-minus:before {
  content: "\ea16";
}
.icofont-taka-plus:before {
  content: "\ea17";
}
.icofont-taka-true:before {
  content: "\ea18";
}
.icofont-taka:before {
  content: "\ea19";
}
.icofont-turkish-lira-false:before {
  content: "\ea1a";
}
.icofont-turkish-lira-minus:before {
  content: "\ea1b";
}
.icofont-turkish-lira-plus:before {
  content: "\ea1c";
}
.icofont-turkish-lira-true:before {
  content: "\ea1d";
}
.icofont-turkish-lira:before {
  content: "\ea1e";
}
.icofont-won-false:before {
  content: "\ea1f";
}
.icofont-won-minus:before {
  content: "\ea20";
}
.icofont-won-plus:before {
  content: "\ea21";
}
.icofont-won-true:before {
  content: "\ea22";
}
.icofont-won:before {
  content: "\ea23";
}
.icofont-yen-false:before {
  content: "\ea24";
}
.icofont-yen-minus:before {
  content: "\ea25";
}
.icofont-yen-plus:before {
  content: "\ea26";
}
.icofont-yen-true:before {
  content: "\ea27";
}
.icofont-yen:before {
  content: "\ea28";
}
.icofont-android-nexus:before {
  content: "\ea29";
}
.icofont-android-tablet:before {
  content: "\ea2a";
}
.icofont-apple-watch:before {
  content: "\ea2b";
}
.icofont-drawing-tablet:before {
  content: "\ea2c";
}
.icofont-earphone:before {
  content: "\ea2d";
}
.icofont-flash-drive:before {
  content: "\ea2e";
}
.icofont-game-console:before {
  content: "\ea2f";
}
.icofont-game-controller:before {
  content: "\ea30";
}
.icofont-game-pad:before {
  content: "\ea31";
}
.icofont-game:before {
  content: "\ea32";
}
.icofont-headphone-alt-1:before {
  content: "\ea33";
}
.icofont-headphone-alt-2:before {
  content: "\ea34";
}
.icofont-headphone-alt-3:before {
  content: "\ea35";
}
.icofont-headphone-alt:before {
  content: "\ea36";
}
.icofont-headphone:before {
  content: "\ea37";
}
.icofont-htc-one:before {
  content: "\ea38";
}
.icofont-imac:before {
  content: "\ea39";
}
.icofont-ipad:before {
  content: "\ea3a";
}
.icofont-iphone:before {
  content: "\ea3b";
}
.icofont-ipod-nano:before {
  content: "\ea3c";
}
.icofont-ipod-touch:before {
  content: "\ea3d";
}
.icofont-keyboard-alt:before {
  content: "\ea3e";
}
.icofont-keyboard-wireless:before {
  content: "\ea3f";
}
.icofont-keyboard:before {
  content: "\ea40";
}
.icofont-laptop-alt:before {
  content: "\ea41";
}
.icofont-laptop:before {
  content: "\ea42";
}
.icofont-macbook:before {
  content: "\ea43";
}
.icofont-magic-mouse:before {
  content: "\ea44";
}
.icofont-micro-chip:before {
  content: "\ea45";
}
.icofont-microphone-alt:before {
  content: "\ea46";
}
.icofont-microphone:before {
  content: "\ea47";
}
.icofont-monitor:before {
  content: "\ea48";
}
.icofont-mouse:before {
  content: "\ea49";
}
.icofont-mp3-player:before {
  content: "\ea4a";
}
.icofont-nintendo:before {
  content: "\ea4b";
}
.icofont-playstation-alt:before {
  content: "\ea4c";
}
.icofont-psvita:before {
  content: "\ea4d";
}
.icofont-radio-mic:before {
  content: "\ea4e";
}
.icofont-radio:before {
  content: "\ea4f";
}
.icofont-refrigerator:before {
  content: "\ea50";
}
.icofont-samsung-galaxy:before {
  content: "\ea51";
}
.icofont-surface-tablet:before {
  content: "\ea52";
}
.icofont-ui-head-phone:before {
  content: "\ea53";
}
.icofont-ui-keyboard:before {
  content: "\ea54";
}
.icofont-washing-machine:before {
  content: "\ea55";
}
.icofont-wifi-router:before {
  content: "\ea56";
}
.icofont-wii-u:before {
  content: "\ea57";
}
.icofont-windows-lumia:before {
  content: "\ea58";
}
.icofont-wireless-mouse:before {
  content: "\ea59";
}
.icofont-xbox-360:before {
  content: "\ea5a";
}
.icofont-arrow-down:before {
  content: "\ea5b";
}
.icofont-arrow-left:before {
  content: "\ea5c";
}
.icofont-arrow-right:before {
  content: "\ea5d";
}
.icofont-arrow-up:before {
  content: "\ea5e";
}
.icofont-block-down:before {
  content: "\ea5f";
}
.icofont-block-left:before {
  content: "\ea60";
}
.icofont-block-right:before {
  content: "\ea61";
}
.icofont-block-up:before {
  content: "\ea62";
}
.icofont-bubble-down:before {
  content: "\ea63";
}
.icofont-bubble-left:before {
  content: "\ea64";
}
.icofont-bubble-right:before {
  content: "\ea65";
}
.icofont-bubble-up:before {
  content: "\ea66";
}
.icofont-caret-down:before {
  content: "\ea67";
}
.icofont-caret-left:before {
  content: "\ea68";
}
.icofont-caret-right:before {
  content: "\ea69";
}
.icofont-caret-up:before {
  content: "\ea6a";
}
.icofont-circled-down:before {
  content: "\ea6b";
}
.icofont-circled-left:before {
  content: "\ea6c";
}
.icofont-circled-right:before {
  content: "\ea6d";
}
.icofont-circled-up:before {
  content: "\ea6e";
}
.icofont-collapse:before {
  content: "\ea6f";
}
.icofont-cursor-drag:before {
  content: "\ea70";
}
.icofont-curved-double-left:before {
  content: "\ea71";
}
.icofont-curved-double-right:before {
  content: "\ea72";
}
.icofont-curved-down:before {
  content: "\ea73";
}
.icofont-curved-left:before {
  content: "\ea74";
}
.icofont-curved-right:before {
  content: "\ea75";
}
.icofont-curved-up:before {
  content: "\ea76";
}
.icofont-dotted-down:before {
  content: "\ea77";
}
.icofont-dotted-left:before {
  content: "\ea78";
}
.icofont-dotted-right:before {
  content: "\ea79";
}
.icofont-dotted-up:before {
  content: "\ea7a";
}
.icofont-double-left:before {
  content: "\ea7b";
}
.icofont-double-right:before {
  content: "\ea7c";
}
.icofont-expand-alt:before {
  content: "\ea7d";
}
.icofont-hand-down:before {
  content: "\ea7e";
}
.icofont-hand-drag:before {
  content: "\ea7f";
}
.icofont-hand-drag1:before {
  content: "\ea80";
}
.icofont-hand-drag2:before {
  content: "\ea81";
}
.icofont-hand-drawn-alt-down:before {
  content: "\ea82";
}
.icofont-hand-drawn-alt-left:before {
  content: "\ea83";
}
.icofont-hand-drawn-alt-right:before {
  content: "\ea84";
}
.icofont-hand-drawn-alt-up:before {
  content: "\ea85";
}
.icofont-hand-drawn-down:before {
  content: "\ea86";
}
.icofont-hand-drawn-left:before {
  content: "\ea87";
}
.icofont-hand-drawn-right:before {
  content: "\ea88";
}
.icofont-hand-drawn-up:before {
  content: "\ea89";
}
.icofont-hand-grippers:before {
  content: "\ea8a";
}
.icofont-hand-left:before {
  content: "\ea8b";
}
.icofont-hand-right:before {
  content: "\ea8c";
}
.icofont-hand-up:before {
  content: "\ea8d";
}
.icofont-line-block-down:before {
  content: "\ea8e";
}
.icofont-line-block-left:before {
  content: "\ea8f";
}
.icofont-line-block-right:before {
  content: "\ea90";
}
.icofont-line-block-up:before {
  content: "\ea91";
}
.icofont-long-arrow-down:before {
  content: "\ea92";
}
.icofont-long-arrow-left:before {
  content: "\ea93";
}
.icofont-long-arrow-right:before {
  content: "\ea94";
}
.icofont-long-arrow-up:before {
  content: "\ea95";
}
.icofont-rounded-collapse:before {
  content: "\ea96";
}
.icofont-rounded-double-left:before {
  content: "\ea97";
}
.icofont-rounded-double-right:before {
  content: "\ea98";
}
.icofont-rounded-down:before {
  content: "\ea99";
}
.icofont-rounded-expand:before {
  content: "\ea9a";
}
.icofont-rounded-left-down:before {
  content: "\ea9b";
}
.icofont-rounded-left-up:before {
  content: "\ea9c";
}
.icofont-rounded-left:before {
  content: "\ea9d";
}
.icofont-rounded-right-down:before {
  content: "\ea9e";
}
.icofont-rounded-right-up:before {
  content: "\ea9f";
}
.icofont-rounded-right:before {
  content: "\eaa0";
}
.icofont-rounded-up:before {
  content: "\eaa1";
}
.icofont-scroll-bubble-down:before {
  content: "\eaa2";
}
.icofont-scroll-bubble-left:before {
  content: "\eaa3";
}
.icofont-scroll-bubble-right:before {
  content: "\eaa4";
}
.icofont-scroll-bubble-up:before {
  content: "\eaa5";
}
.icofont-scroll-double-down:before {
  content: "\eaa6";
}
.icofont-scroll-double-left:before {
  content: "\eaa7";
}
.icofont-scroll-double-right:before {
  content: "\eaa8";
}
.icofont-scroll-double-up:before {
  content: "\eaa9";
}
.icofont-scroll-down:before {
  content: "\eaaa";
}
.icofont-scroll-left:before {
  content: "\eaab";
}
.icofont-scroll-long-down:before {
  content: "\eaac";
}
.icofont-scroll-long-left:before {
  content: "\eaad";
}
.icofont-scroll-long-right:before {
  content: "\eaae";
}
.icofont-scroll-long-up:before {
  content: "\eaaf";
}
.icofont-scroll-right:before {
  content: "\eab0";
}
.icofont-scroll-up:before {
  content: "\eab1";
}
.icofont-simple-down:before {
  content: "\eab2";
}
.icofont-simple-left-down:before {
  content: "\eab3";
}
.icofont-simple-left-up:before {
  content: "\eab4";
}
.icofont-simple-left:before {
  content: "\eab5";
}
.icofont-simple-right-down:before {
  content: "\eab6";
}
.icofont-simple-right-up:before {
  content: "\eab7";
}
.icofont-simple-right:before {
  content: "\eab8";
}
.icofont-simple-up:before {
  content: "\eab9";
}
.icofont-square-down:before {
  content: "\eaba";
}
.icofont-square-left:before {
  content: "\eabb";
}
.icofont-square-right:before {
  content: "\eabc";
}
.icofont-square-up:before {
  content: "\eabd";
}
.icofont-stylish-down:before {
  content: "\eabe";
}
.icofont-stylish-left:before {
  content: "\eabf";
}
.icofont-stylish-right:before {
  content: "\eac0";
}
.icofont-stylish-up:before {
  content: "\eac1";
}
.icofont-swoosh-down:before {
  content: "\eac2";
}
.icofont-swoosh-left:before {
  content: "\eac3";
}
.icofont-swoosh-right:before {
  content: "\eac4";
}
.icofont-swoosh-up:before {
  content: "\eac5";
}
.icofont-thin-double-left:before {
  content: "\eac6";
}
.icofont-thin-double-right:before {
  content: "\eac7";
}
.icofont-thin-down:before {
  content: "\eac8";
}
.icofont-thin-left:before {
  content: "\eac9";
}
.icofont-thin-right:before {
  content: "\eaca";
}
.icofont-thin-up:before {
  content: "\eacb";
}
.icofont-abc:before {
  content: "\eacc";
}
.icofont-atom:before {
  content: "\eacd";
}
.icofont-award:before {
  content: "\eace";
}
.icofont-bell-alt:before {
  content: "\eacf";
}
.icofont-black-board:before {
  content: "\ead0";
}
.icofont-book-alt:before {
  content: "\ead1";
}
.icofont-book:before {
  content: "\ead2";
}
.icofont-brainstorming:before {
  content: "\ead3";
}
.icofont-certificate-alt-1:before {
  content: "\ead4";
}
.icofont-certificate-alt-2:before {
  content: "\ead5";
}
.icofont-certificate:before {
  content: "\ead6";
}
.icofont-education:before {
  content: "\ead7";
}
.icofont-electron:before {
  content: "\ead8";
}
.icofont-fountain-pen:before {
  content: "\ead9";
}
.icofont-globe-alt:before {
  content: "\eada";
}
.icofont-graduate-alt:before {
  content: "\eadb";
}
.icofont-graduate:before {
  content: "\eadc";
}
.icofont-group-students:before {
  content: "\eadd";
}
.icofont-hat-alt:before {
  content: "\eade";
}
.icofont-hat:before {
  content: "\eadf";
}
.icofont-instrument:before {
  content: "\eae0";
}
.icofont-lamp-light:before {
  content: "\eae1";
}
.icofont-medal:before {
  content: "\eae2";
}
.icofont-microscope-alt:before {
  content: "\eae3";
}
.icofont-microscope:before {
  content: "\eae4";
}
.icofont-paper:before {
  content: "\eae5";
}
.icofont-pen-alt-4:before {
  content: "\eae6";
}
.icofont-pen-nib:before {
  content: "\eae7";
}
.icofont-pencil-alt-5:before {
  content: "\eae8";
}
.icofont-quill-pen:before {
  content: "\eae9";
}
.icofont-read-book-alt:before {
  content: "\eaea";
}
.icofont-read-book:before {
  content: "\eaeb";
}
.icofont-school-bag:before {
  content: "\eaec";
}
.icofont-school-bus:before {
  content: "\eaed";
}
.icofont-student-alt:before {
  content: "\eaee";
}
.icofont-student:before {
  content: "\eaef";
}
.icofont-teacher:before {
  content: "\eaf0";
}
.icofont-test-bulb:before {
  content: "\eaf1";
}
.icofont-test-tube-alt:before {
  content: "\eaf2";
}
.icofont-university:before {
  content: "\eaf3";
}
.icofont-angry:before {
  content: "\eaf4";
}
.icofont-astonished:before {
  content: "\eaf5";
}
.icofont-confounded:before {
  content: "\eaf6";
}
.icofont-confused:before {
  content: "\eaf7";
}
.icofont-crying:before {
  content: "\eaf8";
}
.icofont-dizzy:before {
  content: "\eaf9";
}
.icofont-expressionless:before {
  content: "\eafa";
}
.icofont-heart-eyes:before {
  content: "\eafb";
}
.icofont-laughing:before {
  content: "\eafc";
}
.icofont-nerd-smile:before {
  content: "\eafd";
}
.icofont-open-mouth:before {
  content: "\eafe";
}
.icofont-rage:before {
  content: "\eaff";
}
.icofont-rolling-eyes:before {
  content: "\eb00";
}
.icofont-sad:before {
  content: "\eb01";
}
.icofont-simple-smile:before {
  content: "\eb02";
}
.icofont-slightly-smile:before {
  content: "\eb03";
}
.icofont-smirk:before {
  content: "\eb04";
}
.icofont-stuck-out-tongue:before {
  content: "\eb05";
}
.icofont-wink-smile:before {
  content: "\eb06";
}
.icofont-worried:before {
  content: "\eb07";
}
.icofont-file-alt:before {
  content: "\eb08";
}
.icofont-file-audio:before {
  content: "\eb09";
}
.icofont-file-avi-mp4:before {
  content: "\eb0a";
}
.icofont-file-bmp:before {
  content: "\eb0b";
}
.icofont-file-code:before {
  content: "\eb0c";
}
.icofont-file-css:before {
  content: "\eb0d";
}
.icofont-file-document:before {
  content: "\eb0e";
}
.icofont-file-eps:before {
  content: "\eb0f";
}
.icofont-file-excel:before {
  content: "\eb10";
}
.icofont-file-exe:before {
  content: "\eb11";
}
.icofont-file-file:before {
  content: "\eb12";
}
.icofont-file-flv:before {
  content: "\eb13";
}
.icofont-file-gif:before {
  content: "\eb14";
}
.icofont-file-html5:before {
  content: "\eb15";
}
.icofont-file-image:before {
  content: "\eb16";
}
.icofont-file-iso:before {
  content: "\eb17";
}
.icofont-file-java:before {
  content: "\eb18";
}
.icofont-file-javascript:before {
  content: "\eb19";
}
.icofont-file-jpg:before {
  content: "\eb1a";
}
.icofont-file-midi:before {
  content: "\eb1b";
}
.icofont-file-mov:before {
  content: "\eb1c";
}
.icofont-file-mp3:before {
  content: "\eb1d";
}
.icofont-file-pdf:before {
  content: "\eb1e";
}
.icofont-file-php:before {
  content: "\eb1f";
}
.icofont-file-png:before {
  content: "\eb20";
}
.icofont-file-powerpoint:before {
  content: "\eb21";
}
.icofont-file-presentation:before {
  content: "\eb22";
}
.icofont-file-psb:before {
  content: "\eb23";
}
.icofont-file-psd:before {
  content: "\eb24";
}
.icofont-file-python:before {
  content: "\eb25";
}
.icofont-file-ruby:before {
  content: "\eb26";
}
.icofont-file-spreadsheet:before {
  content: "\eb27";
}
.icofont-file-sql:before {
  content: "\eb28";
}
.icofont-file-svg:before {
  content: "\eb29";
}
.icofont-file-text:before {
  content: "\eb2a";
}
.icofont-file-tiff:before {
  content: "\eb2b";
}
.icofont-file-video:before {
  content: "\eb2c";
}
.icofont-file-wave:before {
  content: "\eb2d";
}
.icofont-file-wmv:before {
  content: "\eb2e";
}
.icofont-file-word:before {
  content: "\eb2f";
}
.icofont-file-zip:before {
  content: "\eb30";
}
.icofont-cycling-alt:before {
  content: "\eb31";
}
.icofont-cycling:before {
  content: "\eb32";
}
.icofont-dumbbell:before {
  content: "\eb33";
}
.icofont-dumbbells:before {
  content: "\eb34";
}
.icofont-gym-alt-1:before {
  content: "\eb35";
}
.icofont-gym-alt-2:before {
  content: "\eb36";
}
.icofont-gym-alt-3:before {
  content: "\eb37";
}
.icofont-gym:before {
  content: "\eb38";
}
.icofont-muscle-weight:before {
  content: "\eb39";
}
.icofont-muscle:before {
  content: "\eb3a";
}
.icofont-apple:before {
  content: "\eb3b";
}
.icofont-arabian-coffee:before {
  content: "\eb3c";
}
.icofont-artichoke:before {
  content: "\eb3d";
}
.icofont-asparagus:before {
  content: "\eb3e";
}
.icofont-avocado:before {
  content: "\eb3f";
}
.icofont-baby-food:before {
  content: "\eb40";
}
.icofont-banana:before {
  content: "\eb41";
}
.icofont-bbq:before {
  content: "\eb42";
}
.icofont-beans:before {
  content: "\eb43";
}
.icofont-beer:before {
  content: "\eb44";
}
.icofont-bell-pepper-capsicum:before {
  content: "\eb45";
}
.icofont-birthday-cake:before {
  content: "\eb46";
}
.icofont-bread:before {
  content: "\eb47";
}
.icofont-broccoli:before {
  content: "\eb48";
}
.icofont-burger:before {
  content: "\eb49";
}
.icofont-cabbage:before {
  content: "\eb4a";
}
.icofont-carrot:before {
  content: "\eb4b";
}
.icofont-cauli-flower:before {
  content: "\eb4c";
}
.icofont-cheese:before {
  content: "\eb4d";
}
.icofont-chef:before {
  content: "\eb4e";
}
.icofont-cherry:before {
  content: "\eb4f";
}
.icofont-chicken-fry:before {
  content: "\eb50";
}
.icofont-chicken:before {
  content: "\eb51";
}
.icofont-cocktail:before {
  content: "\eb52";
}
.icofont-coconut-water:before {
  content: "\eb53";
}
.icofont-coconut:before {
  content: "\eb54";
}
.icofont-coffee-alt:before {
  content: "\eb55";
}
.icofont-coffee-cup:before {
  content: "\eb56";
}
.icofont-coffee-mug:before {
  content: "\eb57";
}
.icofont-coffee-pot:before {
  content: "\eb58";
}
.icofont-cola:before {
  content: "\eb59";
}
.icofont-corn:before {
  content: "\eb5a";
}
.icofont-croissant:before {
  content: "\eb5b";
}
.icofont-crop-plant:before {
  content: "\eb5c";
}
.icofont-cucumber:before {
  content: "\eb5d";
}
.icofont-culinary:before {
  content: "\eb5e";
}
.icofont-cup-cake:before {
  content: "\eb5f";
}
.icofont-dining-table:before {
  content: "\eb60";
}
.icofont-donut:before {
  content: "\eb61";
}
.icofont-egg-plant:before {
  content: "\eb62";
}
.icofont-egg-poached:before {
  content: "\eb63";
}
.icofont-farmer-alt:before {
  content: "\eb64";
}
.icofont-farmer:before {
  content: "\eb65";
}
.icofont-fast-food:before {
  content: "\eb66";
}
.icofont-food-basket:before {
  content: "\eb67";
}
.icofont-food-cart:before {
  content: "\eb68";
}
.icofont-fork-and-knife:before {
  content: "\eb69";
}
.icofont-french-fries:before {
  content: "\eb6a";
}
.icofont-fruits:before {
  content: "\eb6b";
}
.icofont-grapes:before {
  content: "\eb6c";
}
.icofont-honey:before {
  content: "\eb6d";
}
.icofont-hot-dog:before {
  content: "\eb6e";
}
.icofont-ice-cream-alt:before {
  content: "\eb6f";
}
.icofont-ice-cream:before {
  content: "\eb70";
}
.icofont-juice:before {
  content: "\eb71";
}
.icofont-ketchup:before {
  content: "\eb72";
}
.icofont-kiwi:before {
  content: "\eb73";
}
.icofont-layered-cake:before {
  content: "\eb74";
}
.icofont-lemon-alt:before {
  content: "\eb75";
}
.icofont-lemon:before {
  content: "\eb76";
}
.icofont-lobster:before {
  content: "\eb77";
}
.icofont-mango:before {
  content: "\eb78";
}
.icofont-milk:before {
  content: "\eb79";
}
.icofont-mushroom:before {
  content: "\eb7a";
}
.icofont-noodles:before {
  content: "\eb7b";
}
.icofont-onion:before {
  content: "\eb7c";
}
.icofont-orange:before {
  content: "\eb7d";
}
.icofont-pear:before {
  content: "\eb7e";
}
.icofont-peas:before {
  content: "\eb7f";
}
.icofont-pepper:before {
  content: "\eb80";
}
.icofont-pie-alt:before {
  content: "\eb81";
}
.icofont-pie:before {
  content: "\eb82";
}
.icofont-pineapple:before {
  content: "\eb83";
}
.icofont-pizza-slice:before {
  content: "\eb84";
}
.icofont-pizza:before {
  content: "\eb85";
}
.icofont-plant:before {
  content: "\eb86";
}
.icofont-popcorn:before {
  content: "\eb87";
}
.icofont-potato:before {
  content: "\eb88";
}
.icofont-pumpkin:before {
  content: "\eb89";
}
.icofont-raddish:before {
  content: "\eb8a";
}
.icofont-restaurant-menu:before {
  content: "\eb8b";
}
.icofont-restaurant:before {
  content: "\eb8c";
}
.icofont-salt-and-pepper:before {
  content: "\eb8d";
}
.icofont-sandwich:before {
  content: "\eb8e";
}
.icofont-sausage:before {
  content: "\eb8f";
}
.icofont-soft-drinks:before {
  content: "\eb90";
}
.icofont-soup-bowl:before {
  content: "\eb91";
}
.icofont-spoon-and-fork:before {
  content: "\eb92";
}
.icofont-steak:before {
  content: "\eb93";
}
.icofont-strawberry:before {
  content: "\eb94";
}
.icofont-sub-sandwich:before {
  content: "\eb95";
}
.icofont-sushi:before {
  content: "\eb96";
}
.icofont-taco:before {
  content: "\eb97";
}
.icofont-tea-pot:before {
  content: "\eb98";
}
.icofont-tea:before {
  content: "\eb99";
}
.icofont-tomato:before {
  content: "\eb9a";
}
.icofont-watermelon:before {
  content: "\eb9b";
}
.icofont-wheat:before {
  content: "\eb9c";
}
.icofont-baby-backpack:before {
  content: "\eb9d";
}
.icofont-baby-cloth:before {
  content: "\eb9e";
}
.icofont-baby-milk-bottle:before {
  content: "\eb9f";
}
.icofont-baby-trolley:before {
  content: "\eba0";
}
.icofont-baby:before {
  content: "\eba1";
}
.icofont-candy:before {
  content: "\eba2";
}
.icofont-holding-hands:before {
  content: "\eba3";
}
.icofont-infant-nipple:before {
  content: "\eba4";
}
.icofont-kids-scooter:before {
  content: "\eba5";
}
.icofont-safety-pin:before {
  content: "\eba6";
}
.icofont-teddy-bear:before {
  content: "\eba7";
}
.icofont-toy-ball:before {
  content: "\eba8";
}
.icofont-toy-cat:before {
  content: "\eba9";
}
.icofont-toy-duck:before {
  content: "\ebaa";
}
.icofont-toy-elephant:before {
  content: "\ebab";
}
.icofont-toy-hand:before {
  content: "\ebac";
}
.icofont-toy-horse:before {
  content: "\ebad";
}
.icofont-toy-lattu:before {
  content: "\ebae";
}
.icofont-toy-train:before {
  content: "\ebaf";
}
.icofont-burglar:before {
  content: "\ebb0";
}
.icofont-cannon-firing:before {
  content: "\ebb1";
}
.icofont-cc-camera:before {
  content: "\ebb2";
}
.icofont-cop-badge:before {
  content: "\ebb3";
}
.icofont-cop:before {
  content: "\ebb4";
}
.icofont-court-hammer:before {
  content: "\ebb5";
}
.icofont-court:before {
  content: "\ebb6";
}
.icofont-finger-print:before {
  content: "\ebb7";
}
.icofont-gavel:before {
  content: "\ebb8";
}
.icofont-handcuff-alt:before {
  content: "\ebb9";
}
.icofont-handcuff:before {
  content: "\ebba";
}
.icofont-investigation:before {
  content: "\ebbb";
}
.icofont-investigator:before {
  content: "\ebbc";
}
.icofont-jail:before {
  content: "\ebbd";
}
.icofont-judge:before {
  content: "\ebbe";
}
.icofont-law-alt-1:before {
  content: "\ebbf";
}
.icofont-law-alt-2:before {
  content: "\ebc0";
}
.icofont-law-alt-3:before {
  content: "\ebc1";
}
.icofont-law-book:before {
  content: "\ebc2";
}
.icofont-law-document:before {
  content: "\ebc3";
}
.icofont-law-order:before {
  content: "\ebc4";
}
.icofont-law-protect:before {
  content: "\ebc5";
}
.icofont-law-scales:before {
  content: "\ebc6";
}
.icofont-law:before {
  content: "\ebc7";
}
.icofont-lawyer-alt-1:before {
  content: "\ebc8";
}
.icofont-lawyer-alt-2:before {
  content: "\ebc9";
}
.icofont-lawyer:before {
  content: "\ebca";
}
.icofont-legal:before {
  content: "\ebcb";
}
.icofont-pistol:before {
  content: "\ebcc";
}
.icofont-police-badge:before {
  content: "\ebcd";
}
.icofont-police-cap:before {
  content: "\ebce";
}
.icofont-police-car-alt-1:before {
  content: "\ebcf";
}
.icofont-police-car-alt-2:before {
  content: "\ebd0";
}
.icofont-police-car:before {
  content: "\ebd1";
}
.icofont-police-hat:before {
  content: "\ebd2";
}
.icofont-police-van:before {
  content: "\ebd3";
}
.icofont-police:before {
  content: "\ebd4";
}
.icofont-thief-alt:before {
  content: "\ebd5";
}
.icofont-thief:before {
  content: "\ebd6";
}
.icofont-abacus-alt:before {
  content: "\ebd7";
}
.icofont-abacus:before {
  content: "\ebd8";
}
.icofont-angle-180:before {
  content: "\ebd9";
}
.icofont-angle-45:before {
  content: "\ebda";
}
.icofont-angle-90:before {
  content: "\ebdb";
}
.icofont-angle:before {
  content: "\ebdc";
}
.icofont-calculator-alt-1:before {
  content: "\ebdd";
}
.icofont-calculator-alt-2:before {
  content: "\ebde";
}
.icofont-calculator:before {
  content: "\ebdf";
}
.icofont-circle-ruler-alt:before {
  content: "\ebe0";
}
.icofont-circle-ruler:before {
  content: "\ebe1";
}
.icofont-compass-alt-1:before {
  content: "\ebe2";
}
.icofont-compass-alt-2:before {
  content: "\ebe3";
}
.icofont-compass-alt-3:before {
  content: "\ebe4";
}
.icofont-compass-alt-4:before {
  content: "\ebe5";
}
.icofont-golden-ratio:before {
  content: "\ebe6";
}
.icofont-marker-alt-1:before {
  content: "\ebe7";
}
.icofont-marker-alt-2:before {
  content: "\ebe8";
}
.icofont-marker-alt-3:before {
  content: "\ebe9";
}
.icofont-marker:before {
  content: "\ebea";
}
.icofont-math:before {
  content: "\ebeb";
}
.icofont-mathematical-alt-1:before {
  content: "\ebec";
}
.icofont-mathematical-alt-2:before {
  content: "\ebed";
}
.icofont-mathematical:before {
  content: "\ebee";
}
.icofont-pen-alt-1:before {
  content: "\ebef";
}
.icofont-pen-alt-2:before {
  content: "\ebf0";
}
.icofont-pen-alt-3:before {
  content: "\ebf1";
}
.icofont-pen-holder-alt-1:before {
  content: "\ebf2";
}
.icofont-pen-holder:before {
  content: "\ebf3";
}
.icofont-pen:before {
  content: "\ebf4";
}
.icofont-pencil-alt-1:before {
  content: "\ebf5";
}
.icofont-pencil-alt-2:before {
  content: "\ebf6";
}
.icofont-pencil-alt-3:before {
  content: "\ebf7";
}
.icofont-pencil-alt-4:before {
  content: "\ebf8";
}
.icofont-pencil:before {
  content: "\ebf9";
}
.icofont-ruler-alt-1:before {
  content: "\ebfa";
}
.icofont-ruler-alt-2:before {
  content: "\ebfb";
}
.icofont-ruler-compass-alt:before {
  content: "\ebfc";
}
.icofont-ruler-compass:before {
  content: "\ebfd";
}
.icofont-ruler-pencil-alt-1:before {
  content: "\ebfe";
}
.icofont-ruler-pencil-alt-2:before {
  content: "\ebff";
}
.icofont-ruler-pencil:before {
  content: "\ec00";
}
.icofont-ruler:before {
  content: "\ec01";
}
.icofont-rulers-alt:before {
  content: "\ec02";
}
.icofont-rulers:before {
  content: "\ec03";
}
.icofont-square-root:before {
  content: "\ec04";
}
.icofont-ui-calculator:before {
  content: "\ec05";
}
.icofont-aids:before {
  content: "\ec06";
}
.icofont-ambulance-crescent:before {
  content: "\ec07";
}
.icofont-ambulance-cross:before {
  content: "\ec08";
}
.icofont-ambulance:before {
  content: "\ec09";
}
.icofont-autism:before {
  content: "\ec0a";
}
.icofont-bandage:before {
  content: "\ec0b";
}
.icofont-blind:before {
  content: "\ec0c";
}
.icofont-blood-drop:before {
  content: "\ec0d";
}
.icofont-blood-test:before {
  content: "\ec0e";
}
.icofont-blood:before {
  content: "\ec0f";
}
.icofont-brain-alt:before {
  content: "\ec10";
}
.icofont-brain:before {
  content: "\ec11";
}
.icofont-capsule:before {
  content: "\ec12";
}
.icofont-crutch:before {
  content: "\ec13";
}
.icofont-disabled:before {
  content: "\ec14";
}
.icofont-dna-alt-1:before {
  content: "\ec15";
}
.icofont-dna-alt-2:before {
  content: "\ec16";
}
.icofont-dna:before {
  content: "\ec17";
}
.icofont-doctor-alt:before {
  content: "\ec18";
}
.icofont-doctor:before {
  content: "\ec19";
}
.icofont-drug-pack:before {
  content: "\ec1a";
}
.icofont-drug:before {
  content: "\ec1b";
}
.icofont-first-aid-alt:before {
  content: "\ec1c";
}
.icofont-first-aid:before {
  content: "\ec1d";
}
.icofont-heart-beat-alt:before {
  content: "\ec1e";
}
.icofont-heart-beat:before {
  content: "\ec1f";
}
.icofont-heartbeat:before {
  content: "\ec20";
}
.icofont-herbal:before {
  content: "\ec21";
}
.icofont-hospital:before {
  content: "\ec22";
}
.icofont-icu:before {
  content: "\ec23";
}
.icofont-injection-syringe:before {
  content: "\ec24";
}
.icofont-laboratory:before {
  content: "\ec25";
}
.icofont-medical-sign-alt:before {
  content: "\ec26";
}
.icofont-medical-sign:before {
  content: "\ec27";
}
.icofont-nurse-alt:before {
  content: "\ec28";
}
.icofont-nurse:before {
  content: "\ec29";
}
.icofont-nursing-home:before {
  content: "\ec2a";
}
.icofont-operation-theater:before {
  content: "\ec2b";
}
.icofont-paralysis-disability:before {
  content: "\ec2c";
}
.icofont-patient-bed:before {
  content: "\ec2d";
}
.icofont-patient-file:before {
  content: "\ec2e";
}
.icofont-pills:before {
  content: "\ec2f";
}
.icofont-prescription:before {
  content: "\ec30";
}
.icofont-pulse:before {
  content: "\ec31";
}
.icofont-stethoscope-alt:before {
  content: "\ec32";
}
.icofont-stethoscope:before {
  content: "\ec33";
}
.icofont-stretcher:before {
  content: "\ec34";
}
.icofont-surgeon-alt:before {
  content: "\ec35";
}
.icofont-surgeon:before {
  content: "\ec36";
}
.icofont-tablets:before {
  content: "\ec37";
}
.icofont-test-bottle:before {
  content: "\ec38";
}
.icofont-test-tube:before {
  content: "\ec39";
}
.icofont-thermometer-alt:before {
  content: "\ec3a";
}
.icofont-thermometer:before {
  content: "\ec3b";
}
.icofont-tooth:before {
  content: "\ec3c";
}
.icofont-xray:before {
  content: "\ec3d";
}
.icofont-ui-add:before {
  content: "\ec3e";
}
.icofont-ui-alarm:before {
  content: "\ec3f";
}
.icofont-ui-battery:before {
  content: "\ec40";
}
.icofont-ui-block:before {
  content: "\ec41";
}
.icofont-ui-bluetooth:before {
  content: "\ec42";
}
.icofont-ui-brightness:before {
  content: "\ec43";
}
.icofont-ui-browser:before {
  content: "\ec44";
}
.icofont-ui-calendar:before {
  content: "\ec45";
}
.icofont-ui-call:before {
  content: "\ec46";
}
.icofont-ui-camera:before {
  content: "\ec47";
}
.icofont-ui-cart:before {
  content: "\ec48";
}
.icofont-ui-cell-phone:before {
  content: "\ec49";
}
.icofont-ui-chat:before {
  content: "\ec4a";
}
.icofont-ui-check:before {
  content: "\ec4b";
}
.icofont-ui-clip-board:before {
  content: "\ec4c";
}
.icofont-ui-clip:before {
  content: "\ec4d";
}
.icofont-ui-clock:before {
  content: "\ec4e";
}
.icofont-ui-close:before {
  content: "\ec4f";
}
.icofont-ui-contact-list:before {
  content: "\ec50";
}
.icofont-ui-copy:before {
  content: "\ec51";
}
.icofont-ui-cut:before {
  content: "\ec52";
}
.icofont-ui-delete:before {
  content: "\ec53";
}
.icofont-ui-dial-phone:before {
  content: "\ec54";
}
.icofont-ui-edit:before {
  content: "\ec55";
}
.icofont-ui-email:before {
  content: "\ec56";
}
.icofont-ui-file:before {
  content: "\ec57";
}
.icofont-ui-fire-wall:before {
  content: "\ec58";
}
.icofont-ui-flash-light:before {
  content: "\ec59";
}
.icofont-ui-flight:before {
  content: "\ec5a";
}
.icofont-ui-folder:before {
  content: "\ec5b";
}
.icofont-ui-game:before {
  content: "\ec5c";
}
.icofont-ui-handicapped:before {
  content: "\ec5d";
}
.icofont-ui-home:before {
  content: "\ec5e";
}
.icofont-ui-image:before {
  content: "\ec5f";
}
.icofont-ui-laoding:before {
  content: "\ec60";
}
.icofont-ui-lock:before {
  content: "\ec61";
}
.icofont-ui-love-add:before {
  content: "\ec62";
}
.icofont-ui-love-broken:before {
  content: "\ec63";
}
.icofont-ui-love-remove:before {
  content: "\ec64";
}
.icofont-ui-love:before {
  content: "\ec65";
}
.icofont-ui-map:before {
  content: "\ec66";
}
.icofont-ui-message:before {
  content: "\ec67";
}
.icofont-ui-messaging:before {
  content: "\ec68";
}
.icofont-ui-movie:before {
  content: "\ec69";
}
.icofont-ui-music-player:before {
  content: "\ec6a";
}
.icofont-ui-music:before {
  content: "\ec6b";
}
.icofont-ui-mute:before {
  content: "\ec6c";
}
.icofont-ui-network:before {
  content: "\ec6d";
}
.icofont-ui-next:before {
  content: "\ec6e";
}
.icofont-ui-note:before {
  content: "\ec6f";
}
.icofont-ui-office:before {
  content: "\ec70";
}
.icofont-ui-password:before {
  content: "\ec71";
}
.icofont-ui-pause:before {
  content: "\ec72";
}
.icofont-ui-play-stop:before {
  content: "\ec73";
}
.icofont-ui-play:before {
  content: "\ec74";
}
.icofont-ui-pointer:before {
  content: "\ec75";
}
.icofont-ui-power:before {
  content: "\ec76";
}
.icofont-ui-press:before {
  content: "\ec77";
}
.icofont-ui-previous:before {
  content: "\ec78";
}
.icofont-ui-rate-add:before {
  content: "\ec79";
}
.icofont-ui-rate-blank:before {
  content: "\ec7a";
}
.icofont-ui-rate-remove:before {
  content: "\ec7b";
}
.icofont-ui-rating:before {
  content: "\ec7c";
}
.icofont-ui-record:before {
  content: "\ec7d";
}
.icofont-ui-remove:before {
  content: "\ec7e";
}
.icofont-ui-reply:before {
  content: "\ec7f";
}
.icofont-ui-rotation:before {
  content: "\ec80";
}
.icofont-ui-rss:before {
  content: "\ec81";
}
.icofont-ui-search:before {
  content: "\ec82";
}
.icofont-ui-settings:before {
  content: "\ec83";
}
.icofont-ui-social-link:before {
  content: "\ec84";
}
.icofont-ui-tag:before {
  content: "\ec85";
}
.icofont-ui-text-chat:before {
  content: "\ec86";
}
.icofont-ui-text-loading:before {
  content: "\ec87";
}
.icofont-ui-theme:before {
  content: "\ec88";
}
.icofont-ui-timer:before {
  content: "\ec89";
}
.icofont-ui-touch-phone:before {
  content: "\ec8a";
}
.icofont-ui-travel:before {
  content: "\ec8b";
}
.icofont-ui-unlock:before {
  content: "\ec8c";
}
.icofont-ui-user-group:before {
  content: "\ec8d";
}
.icofont-ui-user:before {
  content: "\ec8e";
}
.icofont-ui-v-card:before {
  content: "\ec8f";
}
.icofont-ui-video-chat:before {
  content: "\ec90";
}
.icofont-ui-video-message:before {
  content: "\ec91";
}
.icofont-ui-video-play:before {
  content: "\ec92";
}
.icofont-ui-video:before {
  content: "\ec93";
}
.icofont-ui-volume:before {
  content: "\ec94";
}
.icofont-ui-weather:before {
  content: "\ec95";
}
.icofont-ui-wifi:before {
  content: "\ec96";
}
.icofont-ui-zoom-in:before {
  content: "\ec97";
}
.icofont-ui-zoom-out:before {
  content: "\ec98";
}
.icofont-cassette-player:before {
  content: "\ec99";
}
.icofont-cassette:before {
  content: "\ec9a";
}
.icofont-forward:before {
  content: "\ec9b";
}
.icofont-guiter:before {
  content: "\ec9c";
}
.icofont-movie:before {
  content: "\ec9d";
}
.icofont-multimedia:before {
  content: "\ec9e";
}
.icofont-music-alt:before {
  content: "\ec9f";
}
.icofont-music-disk:before {
  content: "\eca0";
}
.icofont-music-note:before {
  content: "\eca1";
}
.icofont-music-notes:before {
  content: "\eca2";
}
.icofont-music:before {
  content: "\eca3";
}
.icofont-mute-volume:before {
  content: "\eca4";
}
.icofont-pause:before {
  content: "\eca5";
}
.icofont-play-alt-1:before {
  content: "\eca6";
}
.icofont-play-alt-2:before {
  content: "\eca7";
}
.icofont-play-alt-3:before {
  content: "\eca8";
}
.icofont-play-pause:before {
  content: "\eca9";
}
.icofont-play:before {
  content: "\ecaa";
}
.icofont-record:before {
  content: "\ecab";
}
.icofont-retro-music-disk:before {
  content: "\ecac";
}
.icofont-rewind:before {
  content: "\ecad";
}
.icofont-song-notes:before {
  content: "\ecae";
}
.icofont-sound-wave-alt:before {
  content: "\ecaf";
}
.icofont-sound-wave:before {
  content: "\ecb0";
}
.icofont-stop:before {
  content: "\ecb1";
}
.icofont-video-alt:before {
  content: "\ecb2";
}
.icofont-video-cam:before {
  content: "\ecb3";
}
.icofont-video-clapper:before {
  content: "\ecb4";
}
.icofont-video:before {
  content: "\ecb5";
}
.icofont-volume-bar:before {
  content: "\ecb6";
}
.icofont-volume-down:before {
  content: "\ecb7";
}
.icofont-volume-mute:before {
  content: "\ecb8";
}
.icofont-volume-off:before {
  content: "\ecb9";
}
.icofont-volume-up:before {
  content: "\ecba";
}
.icofont-youtube-play:before {
  content: "\ecbb";
}
.icofont-2checkout-alt:before {
  content: "\ecbc";
}
.icofont-2checkout:before {
  content: "\ecbd";
}
.icofont-amazon-alt:before {
  content: "\ecbe";
}
.icofont-amazon:before {
  content: "\ecbf";
}
.icofont-american-express-alt:before {
  content: "\ecc0";
}
.icofont-american-express:before {
  content: "\ecc1";
}
.icofont-apple-pay-alt:before {
  content: "\ecc2";
}
.icofont-apple-pay:before {
  content: "\ecc3";
}
.icofont-bank-transfer-alt:before {
  content: "\ecc4";
}
.icofont-bank-transfer:before {
  content: "\ecc5";
}
.icofont-braintree-alt:before {
  content: "\ecc6";
}
.icofont-braintree:before {
  content: "\ecc7";
}
.icofont-cash-on-delivery-alt:before {
  content: "\ecc8";
}
.icofont-cash-on-delivery:before {
  content: "\ecc9";
}
.icofont-diners-club-alt-1:before {
  content: "\ecca";
}
.icofont-diners-club-alt-2:before {
  content: "\eccb";
}
.icofont-diners-club-alt-3:before {
  content: "\eccc";
}
.icofont-diners-club:before {
  content: "\eccd";
}
.icofont-discover-alt:before {
  content: "\ecce";
}
.icofont-discover:before {
  content: "\eccf";
}
.icofont-eway-alt:before {
  content: "\ecd0";
}
.icofont-eway:before {
  content: "\ecd1";
}
.icofont-google-wallet-alt-1:before {
  content: "\ecd2";
}
.icofont-google-wallet-alt-2:before {
  content: "\ecd3";
}
.icofont-google-wallet-alt-3:before {
  content: "\ecd4";
}
.icofont-google-wallet:before {
  content: "\ecd5";
}
.icofont-jcb-alt:before {
  content: "\ecd6";
}
.icofont-jcb:before {
  content: "\ecd7";
}
.icofont-maestro-alt:before {
  content: "\ecd8";
}
.icofont-maestro:before {
  content: "\ecd9";
}
.icofont-mastercard-alt:before {
  content: "\ecda";
}
.icofont-mastercard:before {
  content: "\ecdb";
}
.icofont-payoneer-alt:before {
  content: "\ecdc";
}
.icofont-payoneer:before {
  content: "\ecdd";
}
.icofont-paypal-alt:before {
  content: "\ecde";
}
.icofont-paypal:before {
  content: "\ecdf";
}
.icofont-sage-alt:before {
  content: "\ece0";
}
.icofont-sage:before {
  content: "\ece1";
}
.icofont-skrill-alt:before {
  content: "\ece2";
}
.icofont-skrill:before {
  content: "\ece3";
}
.icofont-stripe-alt:before {
  content: "\ece4";
}
.icofont-stripe:before {
  content: "\ece5";
}
.icofont-visa-alt:before {
  content: "\ece6";
}
.icofont-visa-electron:before {
  content: "\ece7";
}
.icofont-visa:before {
  content: "\ece8";
}
.icofont-western-union-alt:before {
  content: "\ece9";
}
.icofont-western-union:before {
  content: "\ecea";
}
.icofont-boy:before {
  content: "\eceb";
}
.icofont-business-man-alt-1:before {
  content: "\ecec";
}
.icofont-business-man-alt-2:before {
  content: "\eced";
}
.icofont-business-man-alt-3:before {
  content: "\ecee";
}
.icofont-business-man:before {
  content: "\ecef";
}
.icofont-female:before {
  content: "\ecf0";
}
.icofont-funky-man:before {
  content: "\ecf1";
}
.icofont-girl-alt:before {
  content: "\ecf2";
}
.icofont-girl:before {
  content: "\ecf3";
}
.icofont-group:before {
  content: "\ecf4";
}
.icofont-hotel-boy-alt:before {
  content: "\ecf5";
}
.icofont-hotel-boy:before {
  content: "\ecf6";
}
.icofont-kid:before {
  content: "\ecf7";
}
.icofont-man-in-glasses:before {
  content: "\ecf8";
}
.icofont-people:before {
  content: "\ecf9";
}
.icofont-support:before {
  content: "\ecfa";
}
.icofont-user-alt-1:before {
  content: "\ecfb";
}
.icofont-user-alt-2:before {
  content: "\ecfc";
}
.icofont-user-alt-3:before {
  content: "\ecfd";
}
.icofont-user-alt-4:before {
  content: "\ecfe";
}
.icofont-user-alt-5:before {
  content: "\ecff";
}
.icofont-user-alt-6:before {
  content: "\ed00";
}
.icofont-user-alt-7:before {
  content: "\ed01";
}
.icofont-user-female:before {
  content: "\ed02";
}
.icofont-user-male:before {
  content: "\ed03";
}
.icofont-user-suited:before {
  content: "\ed04";
}
.icofont-user:before {
  content: "\ed05";
}
.icofont-users-alt-1:before {
  content: "\ed06";
}
.icofont-users-alt-2:before {
  content: "\ed07";
}
.icofont-users-alt-3:before {
  content: "\ed08";
}
.icofont-users-alt-4:before {
  content: "\ed09";
}
.icofont-users-alt-5:before {
  content: "\ed0a";
}
.icofont-users-alt-6:before {
  content: "\ed0b";
}
.icofont-users-social:before {
  content: "\ed0c";
}
.icofont-users:before {
  content: "\ed0d";
}
.icofont-waiter-alt:before {
  content: "\ed0e";
}
.icofont-waiter:before {
  content: "\ed0f";
}
.icofont-woman-in-glasses:before {
  content: "\ed10";
}
.icofont-search-1:before {
  content: "\ed11";
}
.icofont-search-2:before {
  content: "\ed12";
}
.icofont-search-document:before {
  content: "\ed13";
}
.icofont-search-folder:before {
  content: "\ed14";
}
.icofont-search-job:before {
  content: "\ed15";
}
.icofont-search-map:before {
  content: "\ed16";
}
.icofont-search-property:before {
  content: "\ed17";
}
.icofont-search-restaurant:before {
  content: "\ed18";
}
.icofont-search-stock:before {
  content: "\ed19";
}
.icofont-search-user:before {
  content: "\ed1a";
}
.icofont-search:before {
  content: "\ed1b";
}
.icofont-500px:before {
  content: "\ed1c";
}
.icofont-aim:before {
  content: "\ed1d";
}
.icofont-badoo:before {
  content: "\ed1e";
}
.icofont-baidu-tieba:before {
  content: "\ed1f";
}
.icofont-bbm-messenger:before {
  content: "\ed20";
}
.icofont-bebo:before {
  content: "\ed21";
}
.icofont-behance:before {
  content: "\ed22";
}
.icofont-blogger:before {
  content: "\ed23";
}
.icofont-bootstrap:before {
  content: "\ed24";
}
.icofont-brightkite:before {
  content: "\ed25";
}
.icofont-cloudapp:before {
  content: "\ed26";
}
.icofont-concrete5:before {
  content: "\ed27";
}
.icofont-delicious:before {
  content: "\ed28";
}
.icofont-designbump:before {
  content: "\ed29";
}
.icofont-designfloat:before {
  content: "\ed2a";
}
.icofont-deviantart:before {
  content: "\ed2b";
}
.icofont-digg:before {
  content: "\ed2c";
}
.icofont-dotcms:before {
  content: "\ed2d";
}
.icofont-dribbble:before {
  content: "\ed2e";
}
.icofont-dribble:before {
  content: "\ed2f";
}
.icofont-dropbox:before {
  content: "\ed30";
}
.icofont-ebuddy:before {
  content: "\ed31";
}
.icofont-ello:before {
  content: "\ed32";
}
.icofont-ember:before {
  content: "\ed33";
}
.icofont-envato:before {
  content: "\ed34";
}
.icofont-evernote:before {
  content: "\ed35";
}
.icofont-facebook-messenger:before {
  content: "\ed36";
}
.icofont-facebook:before {
  content: "\ed37";
}
.icofont-feedburner:before {
  content: "\ed38";
}
.icofont-flikr:before {
  content: "\ed39";
}
.icofont-folkd:before {
  content: "\ed3a";
}
.icofont-foursquare:before {
  content: "\ed3b";
}
.icofont-friendfeed:before {
  content: "\ed3c";
}
.icofont-ghost:before {
  content: "\ed3d";
}
.icofont-github:before {
  content: "\ed3e";
}
.icofont-gnome:before {
  content: "\ed3f";
}
.icofont-google-buzz:before {
  content: "\ed40";
}
.icofont-google-hangouts:before {
  content: "\ed41";
}
.icofont-google-map:before {
  content: "\ed42";
}
.icofont-google-plus:before {
  content: "\ed43";
}
.icofont-google-talk:before {
  content: "\ed44";
}
.icofont-hype-machine:before {
  content: "\ed45";
}
.icofont-instagram:before {
  content: "\ed46";
}
.icofont-kakaotalk:before {
  content: "\ed47";
}
.icofont-kickstarter:before {
  content: "\ed48";
}
.icofont-kik:before {
  content: "\ed49";
}
.icofont-kiwibox:before {
  content: "\ed4a";
}
.icofont-line-messenger:before {
  content: "\ed4b";
}
.icofont-line:before {
  content: "\ed4c";
}
.icofont-linkedin:before {
  content: "\ed4d";
}
.icofont-linux-mint:before {
  content: "\ed4e";
}
.icofont-live-messenger:before {
  content: "\ed4f";
}
.icofont-livejournal:before {
  content: "\ed50";
}
.icofont-magento:before {
  content: "\ed51";
}
.icofont-meetme:before {
  content: "\ed52";
}
.icofont-meetup:before {
  content: "\ed53";
}
.icofont-mixx:before {
  content: "\ed54";
}
.icofont-newsvine:before {
  content: "\ed55";
}
.icofont-nimbuss:before {
  content: "\ed56";
}
.icofont-odnoklassniki:before {
  content: "\ed57";
}
.icofont-opencart:before {
  content: "\ed58";
}
.icofont-oscommerce:before {
  content: "\ed59";
}
.icofont-pandora:before {
  content: "\ed5a";
}
.icofont-photobucket:before {
  content: "\ed5b";
}
.icofont-picasa:before {
  content: "\ed5c";
}
.icofont-pinterest:before {
  content: "\ed5d";
}
.icofont-prestashop:before {
  content: "\ed5e";
}
.icofont-qik:before {
  content: "\ed5f";
}
.icofont-qq:before {
  content: "\ed60";
}
.icofont-readernaut:before {
  content: "\ed61";
}
.icofont-reddit:before {
  content: "\ed62";
}
.icofont-renren:before {
  content: "\ed63";
}
.icofont-rss:before {
  content: "\ed64";
}
.icofont-shopify:before {
  content: "\ed65";
}
.icofont-silverstripe:before {
  content: "\ed66";
}
.icofont-skype:before {
  content: "\ed67";
}
.icofont-slack:before {
  content: "\ed68";
}
.icofont-slashdot:before {
  content: "\ed69";
}
.icofont-slidshare:before {
  content: "\ed6a";
}
.icofont-smugmug:before {
  content: "\ed6b";
}
.icofont-snapchat:before {
  content: "\ed6c";
}
.icofont-soundcloud:before {
  content: "\ed6d";
}
.icofont-spotify:before {
  content: "\ed6e";
}
.icofont-stack-exchange:before {
  content: "\ed6f";
}
.icofont-stack-overflow:before {
  content: "\ed70";
}
.icofont-steam:before {
  content: "\ed71";
}
.icofont-stumbleupon:before {
  content: "\ed72";
}
.icofont-tagged:before {
  content: "\ed73";
}
.icofont-technorati:before {
  content: "\ed74";
}
.icofont-telegram:before {
  content: "\ed75";
}
.icofont-tinder:before {
  content: "\ed76";
}
.icofont-trello:before {
  content: "\ed77";
}
.icofont-tumblr:before {
  content: "\ed78";
}
.icofont-twitch:before {
  content: "\ed79";
}
.icofont-twitter:before {
  content: "\ed7a";
}
.icofont-typo3:before {
  content: "\ed7b";
}
.icofont-ubercart:before {
  content: "\ed7c";
}
.icofont-viber:before {
  content: "\ed7d";
}
.icofont-viddler:before {
  content: "\ed7e";
}
.icofont-vimeo:before {
  content: "\ed7f";
}
.icofont-vine:before {
  content: "\ed80";
}
.icofont-virb:before {
  content: "\ed81";
}
.icofont-virtuemart:before {
  content: "\ed82";
}
.icofont-vk:before {
  content: "\ed83";
}
.icofont-wechat:before {
  content: "\ed84";
}
.icofont-weibo:before {
  content: "\ed85";
}
.icofont-whatsapp:before {
  content: "\ed86";
}
.icofont-xing:before {
  content: "\ed87";
}
.icofont-yahoo:before {
  content: "\ed88";
}
.icofont-yelp:before {
  content: "\ed89";
}
.icofont-youku:before {
  content: "\ed8a";
}
.icofont-youtube:before {
  content: "\ed8b";
}
.icofont-zencart:before {
  content: "\ed8c";
}
.icofont-badminton-birdie:before {
  content: "\ed8d";
}
.icofont-baseball:before {
  content: "\ed8e";
}
.icofont-baseballer:before {
  content: "\ed8f";
}
.icofont-basketball-hoop:before {
  content: "\ed90";
}
.icofont-basketball:before {
  content: "\ed91";
}
.icofont-billiard-ball:before {
  content: "\ed92";
}
.icofont-boot-alt-1:before {
  content: "\ed93";
}
.icofont-boot-alt-2:before {
  content: "\ed94";
}
.icofont-boot:before {
  content: "\ed95";
}
.icofont-bowling-alt:before {
  content: "\ed96";
}
.icofont-bowling:before {
  content: "\ed97";
}
.icofont-canoe:before {
  content: "\ed98";
}
.icofont-cheer-leader:before {
  content: "\ed99";
}
.icofont-climbing:before {
  content: "\ed9a";
}
.icofont-corner:before {
  content: "\ed9b";
}
.icofont-field-alt:before {
  content: "\ed9c";
}
.icofont-field:before {
  content: "\ed9d";
}
.icofont-football-alt:before {
  content: "\ed9e";
}
.icofont-football-american:before {
  content: "\ed9f";
}
.icofont-football:before {
  content: "\eda0";
}
.icofont-foul:before {
  content: "\eda1";
}
.icofont-goal-keeper:before {
  content: "\eda2";
}
.icofont-goal:before {
  content: "\eda3";
}
.icofont-golf-alt:before {
  content: "\eda4";
}
.icofont-golf-bag:before {
  content: "\eda5";
}
.icofont-golf-cart:before {
  content: "\eda6";
}
.icofont-golf-field:before {
  content: "\eda7";
}
.icofont-golf:before {
  content: "\eda8";
}
.icofont-golfer:before {
  content: "\eda9";
}
.icofont-helmet:before {
  content: "\edaa";
}
.icofont-hockey-alt:before {
  content: "\edab";
}
.icofont-hockey:before {
  content: "\edac";
}
.icofont-ice-skate:before {
  content: "\edad";
}
.icofont-jersey-alt:before {
  content: "\edae";
}
.icofont-jersey:before {
  content: "\edaf";
}
.icofont-jumping:before {
  content: "\edb0";
}
.icofont-kick:before {
  content: "\edb1";
}
.icofont-leg:before {
  content: "\edb2";
}
.icofont-match-review:before {
  content: "\edb3";
}
.icofont-medal-sport:before {
  content: "\edb4";
}
.icofont-offside:before {
  content: "\edb5";
}
.icofont-olympic-logo:before {
  content: "\edb6";
}
.icofont-olympic:before {
  content: "\edb7";
}
.icofont-padding:before {
  content: "\edb8";
}
.icofont-penalty-card:before {
  content: "\edb9";
}
.icofont-racer:before {
  content: "\edba";
}
.icofont-racing-car:before {
  content: "\edbb";
}
.icofont-racing-flag-alt:before {
  content: "\edbc";
}
.icofont-racing-flag:before {
  content: "\edbd";
}
.icofont-racings-wheel:before {
  content: "\edbe";
}
.icofont-referee:before {
  content: "\edbf";
}
.icofont-refree-jersey:before {
  content: "\edc0";
}
.icofont-result-sport:before {
  content: "\edc1";
}
.icofont-rugby-ball:before {
  content: "\edc2";
}
.icofont-rugby-player:before {
  content: "\edc3";
}
.icofont-rugby:before {
  content: "\edc4";
}
.icofont-runner-alt-1:before {
  content: "\edc5";
}
.icofont-runner-alt-2:before {
  content: "\edc6";
}
.icofont-runner:before {
  content: "\edc7";
}
.icofont-score-board:before {
  content: "\edc8";
}
.icofont-skiing-man:before {
  content: "\edc9";
}
.icofont-skydiving-goggles:before {
  content: "\edca";
}
.icofont-snow-mobile:before {
  content: "\edcb";
}
.icofont-steering:before {
  content: "\edcc";
}
.icofont-stopwatch:before {
  content: "\edcd";
}
.icofont-substitute:before {
  content: "\edce";
}
.icofont-swimmer:before {
  content: "\edcf";
}
.icofont-table-tennis:before {
  content: "\edd0";
}
.icofont-team-alt:before {
  content: "\edd1";
}
.icofont-team:before {
  content: "\edd2";
}
.icofont-tennis-player:before {
  content: "\edd3";
}
.icofont-tennis:before {
  content: "\edd4";
}
.icofont-tracking:before {
  content: "\edd5";
}
.icofont-trophy-alt:before {
  content: "\edd6";
}
.icofont-trophy:before {
  content: "\edd7";
}
.icofont-volleyball-alt:before {
  content: "\edd8";
}
.icofont-volleyball-fire:before {
  content: "\edd9";
}
.icofont-volleyball:before {
  content: "\edda";
}
.icofont-water-bottle:before {
  content: "\eddb";
}
.icofont-whistle-alt:before {
  content: "\eddc";
}
.icofont-whistle:before {
  content: "\eddd";
}
.icofont-win-trophy:before {
  content: "\edde";
}
.icofont-align-center:before {
  content: "\eddf";
}
.icofont-align-left:before {
  content: "\ede0";
}
.icofont-align-right:before {
  content: "\ede1";
}
.icofont-all-caps:before {
  content: "\ede2";
}
.icofont-bold:before {
  content: "\ede3";
}
.icofont-brush:before {
  content: "\ede4";
}
.icofont-clip-board:before {
  content: "\ede5";
}
.icofont-code-alt:before {
  content: "\ede6";
}
.icofont-color-bucket:before {
  content: "\ede7";
}
.icofont-color-picker:before {
  content: "\ede8";
}
.icofont-copy-invert:before {
  content: "\ede9";
}
.icofont-copy:before {
  content: "\edea";
}
.icofont-cut:before {
  content: "\edeb";
}
.icofont-delete-alt:before {
  content: "\edec";
}
.icofont-edit-alt:before {
  content: "\eded";
}
.icofont-eraser-alt:before {
  content: "\edee";
}
.icofont-font:before {
  content: "\edef";
}
.icofont-heading:before {
  content: "\edf0";
}
.icofont-indent:before {
  content: "\edf1";
}
.icofont-italic-alt:before {
  content: "\edf2";
}
.icofont-italic:before {
  content: "\edf3";
}
.icofont-justify-all:before {
  content: "\edf4";
}
.icofont-justify-center:before {
  content: "\edf5";
}
.icofont-justify-left:before {
  content: "\edf6";
}
.icofont-justify-right:before {
  content: "\edf7";
}
.icofont-link-broken:before {
  content: "\edf8";
}
.icofont-outdent:before {
  content: "\edf9";
}
.icofont-paper-clip:before {
  content: "\edfa";
}
.icofont-paragraph:before {
  content: "\edfb";
}
.icofont-pin:before {
  content: "\edfc";
}
.icofont-printer:before {
  content: "\edfd";
}
.icofont-redo:before {
  content: "\edfe";
}
.icofont-rotation:before {
  content: "\edff";
}
.icofont-save:before {
  content: "\ee00";
}
.icofont-small-cap:before {
  content: "\ee01";
}
.icofont-strike-through:before {
  content: "\ee02";
}
.icofont-sub-listing:before {
  content: "\ee03";
}
.icofont-subscript:before {
  content: "\ee04";
}
.icofont-superscript:before {
  content: "\ee05";
}
.icofont-table:before {
  content: "\ee06";
}
.icofont-text-height:before {
  content: "\ee07";
}
.icofont-text-width:before {
  content: "\ee08";
}
.icofont-trash:before {
  content: "\ee09";
}
.icofont-underline:before {
  content: "\ee0a";
}
.icofont-undo:before {
  content: "\ee0b";
}
.icofont-air-balloon:before {
  content: "\ee0c";
}
.icofont-airplane-alt:before {
  content: "\ee0d";
}
.icofont-airplane:before {
  content: "\ee0e";
}
.icofont-articulated-truck:before {
  content: "\ee0f";
}
.icofont-auto-mobile:before {
  content: "\ee10";
}
.icofont-auto-rickshaw:before {
  content: "\ee11";
}
.icofont-bicycle-alt-1:before {
  content: "\ee12";
}
.icofont-bicycle-alt-2:before {
  content: "\ee13";
}
.icofont-bicycle:before {
  content: "\ee14";
}
.icofont-bus-alt-1:before {
  content: "\ee15";
}
.icofont-bus-alt-2:before {
  content: "\ee16";
}
.icofont-bus-alt-3:before {
  content: "\ee17";
}
.icofont-bus:before {
  content: "\ee18";
}
.icofont-cab:before {
  content: "\ee19";
}
.icofont-cable-car:before {
  content: "\ee1a";
}
.icofont-car-alt-1:before {
  content: "\ee1b";
}
.icofont-car-alt-2:before {
  content: "\ee1c";
}
.icofont-car-alt-3:before {
  content: "\ee1d";
}
.icofont-car-alt-4:before {
  content: "\ee1e";
}
.icofont-car:before {
  content: "\ee1f";
}
.icofont-delivery-time:before {
  content: "\ee20";
}
.icofont-fast-delivery:before {
  content: "\ee21";
}
.icofont-fire-truck-alt:before {
  content: "\ee22";
}
.icofont-fire-truck:before {
  content: "\ee23";
}
.icofont-free-delivery:before {
  content: "\ee24";
}
.icofont-helicopter:before {
  content: "\ee25";
}
.icofont-motor-bike-alt:before {
  content: "\ee26";
}
.icofont-motor-bike:before {
  content: "\ee27";
}
.icofont-motor-biker:before {
  content: "\ee28";
}
.icofont-oil-truck:before {
  content: "\ee29";
}
.icofont-rickshaw:before {
  content: "\ee2a";
}
.icofont-rocket-alt-1:before {
  content: "\ee2b";
}
.icofont-rocket-alt-2:before {
  content: "\ee2c";
}
.icofont-rocket:before {
  content: "\ee2d";
}
.icofont-sail-boat-alt-1:before {
  content: "\ee2e";
}
.icofont-sail-boat-alt-2:before {
  content: "\ee2f";
}
.icofont-sail-boat:before {
  content: "\ee30";
}
.icofont-scooter:before {
  content: "\ee31";
}
.icofont-sea-plane:before {
  content: "\ee32";
}
.icofont-ship-alt:before {
  content: "\ee33";
}
.icofont-ship:before {
  content: "\ee34";
}
.icofont-speed-boat:before {
  content: "\ee35";
}
.icofont-taxi:before {
  content: "\ee36";
}
.icofont-tractor:before {
  content: "\ee37";
}
.icofont-train-line:before {
  content: "\ee38";
}
.icofont-train-steam:before {
  content: "\ee39";
}
.icofont-tram:before {
  content: "\ee3a";
}
.icofont-truck-alt:before {
  content: "\ee3b";
}
.icofont-truck-loaded:before {
  content: "\ee3c";
}
.icofont-truck:before {
  content: "\ee3d";
}
.icofont-van-alt:before {
  content: "\ee3e";
}
.icofont-van:before {
  content: "\ee3f";
}
.icofont-yacht:before {
  content: "\ee40";
}
.icofont-5-star-hotel:before {
  content: "\ee41";
}
.icofont-air-ticket:before {
  content: "\ee42";
}
.icofont-beach-bed:before {
  content: "\ee43";
}
.icofont-beach:before {
  content: "\ee44";
}
.icofont-camping-vest:before {
  content: "\ee45";
}
.icofont-direction-sign:before {
  content: "\ee46";
}
.icofont-hill-side:before {
  content: "\ee47";
}
.icofont-hill:before {
  content: "\ee48";
}
.icofont-hotel:before {
  content: "\ee49";
}
.icofont-island-alt:before {
  content: "\ee4a";
}
.icofont-island:before {
  content: "\ee4b";
}
.icofont-sandals-female:before {
  content: "\ee4c";
}
.icofont-sandals-male:before {
  content: "\ee4d";
}
.icofont-travelling:before {
  content: "\ee4e";
}
.icofont-breakdown:before {
  content: "\ee4f";
}
.icofont-celsius:before {
  content: "\ee50";
}
.icofont-clouds:before {
  content: "\ee51";
}
.icofont-cloudy:before {
  content: "\ee52";
}
.icofont-dust:before {
  content: "\ee53";
}
.icofont-eclipse:before {
  content: "\ee54";
}
.icofont-fahrenheit:before {
  content: "\ee55";
}
.icofont-forest-fire:before {
  content: "\ee56";
}
.icofont-full-night:before {
  content: "\ee57";
}
.icofont-full-sunny:before {
  content: "\ee58";
}
.icofont-hail-night:before {
  content: "\ee59";
}
.icofont-hail-rainy-night:before {
  content: "\ee5a";
}
.icofont-hail-rainy-sunny:before {
  content: "\ee5b";
}
.icofont-hail-rainy:before {
  content: "\ee5c";
}
.icofont-hail-sunny:before {
  content: "\ee5d";
}
.icofont-hail-thunder-night:before {
  content: "\ee5e";
}
.icofont-hail-thunder-sunny:before {
  content: "\ee5f";
}
.icofont-hail-thunder:before {
  content: "\ee60";
}
.icofont-hail:before {
  content: "\ee61";
}
.icofont-hill-night:before {
  content: "\ee62";
}
.icofont-hill-sunny:before {
  content: "\ee63";
}
.icofont-hurricane:before {
  content: "\ee64";
}
.icofont-meteor:before {
  content: "\ee65";
}
.icofont-night:before {
  content: "\ee66";
}
.icofont-rainy-night:before {
  content: "\ee67";
}
.icofont-rainy-sunny:before {
  content: "\ee68";
}
.icofont-rainy-thunder:before {
  content: "\ee69";
}
.icofont-rainy:before {
  content: "\ee6a";
}
.icofont-snow-alt:before {
  content: "\ee6b";
}
.icofont-snow-flake:before {
  content: "\ee6c";
}
.icofont-snow-temp:before {
  content: "\ee6d";
}
.icofont-snow:before {
  content: "\ee6e";
}
.icofont-snowy-hail:before {
  content: "\ee6f";
}
.icofont-snowy-night-hail:before {
  content: "\ee70";
}
.icofont-snowy-night-rainy:before {
  content: "\ee71";
}
.icofont-snowy-night:before {
  content: "\ee72";
}
.icofont-snowy-rainy:before {
  content: "\ee73";
}
.icofont-snowy-sunny-hail:before {
  content: "\ee74";
}
.icofont-snowy-sunny-rainy:before {
  content: "\ee75";
}
.icofont-snowy-sunny:before {
  content: "\ee76";
}
.icofont-snowy-thunder-night:before {
  content: "\ee77";
}
.icofont-snowy-thunder-sunny:before {
  content: "\ee78";
}
.icofont-snowy-thunder:before {
  content: "\ee79";
}
.icofont-snowy-windy-night:before {
  content: "\ee7a";
}
.icofont-snowy-windy-sunny:before {
  content: "\ee7b";
}
.icofont-snowy-windy:before {
  content: "\ee7c";
}
.icofont-snowy:before {
  content: "\ee7d";
}
.icofont-sun-alt:before {
  content: "\ee7e";
}
.icofont-sun-rise:before {
  content: "\ee7f";
}
.icofont-sun-set:before {
  content: "\ee80";
}
.icofont-sun:before {
  content: "\ee81";
}
.icofont-sunny-day-temp:before {
  content: "\ee82";
}
.icofont-sunny:before {
  content: "\ee83";
}
.icofont-thunder-light:before {
  content: "\ee84";
}
.icofont-tornado:before {
  content: "\ee85";
}
.icofont-umbrella-alt:before {
  content: "\ee86";
}
.icofont-umbrella:before {
  content: "\ee87";
}
.icofont-volcano:before {
  content: "\ee88";
}
.icofont-wave:before {
  content: "\ee89";
}
.icofont-wind-scale-0:before {
  content: "\ee8a";
}
.icofont-wind-scale-1:before {
  content: "\ee8b";
}
.icofont-wind-scale-10:before {
  content: "\ee8c";
}
.icofont-wind-scale-11:before {
  content: "\ee8d";
}
.icofont-wind-scale-12:before {
  content: "\ee8e";
}
.icofont-wind-scale-2:before {
  content: "\ee8f";
}
.icofont-wind-scale-3:before {
  content: "\ee90";
}
.icofont-wind-scale-4:before {
  content: "\ee91";
}
.icofont-wind-scale-5:before {
  content: "\ee92";
}
.icofont-wind-scale-6:before {
  content: "\ee93";
}
.icofont-wind-scale-7:before {
  content: "\ee94";
}
.icofont-wind-scale-8:before {
  content: "\ee95";
}
.icofont-wind-scale-9:before {
  content: "\ee96";
}
.icofont-wind-waves:before {
  content: "\ee97";
}
.icofont-wind:before {
  content: "\ee98";
}
.icofont-windy-hail:before {
  content: "\ee99";
}
.icofont-windy-night:before {
  content: "\ee9a";
}
.icofont-windy-raining:before {
  content: "\ee9b";
}
.icofont-windy-sunny:before {
  content: "\ee9c";
}
.icofont-windy-thunder-raining:before {
  content: "\ee9d";
}
.icofont-windy-thunder:before {
  content: "\ee9e";
}
.icofont-windy:before {
  content: "\ee9f";
}
.icofont-addons:before {
  content: "\eea0";
}
.icofont-address-book:before {
  content: "\eea1";
}
.icofont-adjust:before {
  content: "\eea2";
}
.icofont-alarm:before {
  content: "\eea3";
}
.icofont-anchor:before {
  content: "\eea4";
}
.icofont-archive:before {
  content: "\eea5";
}
.icofont-at:before {
  content: "\eea6";
}
.icofont-attachment:before {
  content: "\eea7";
}
.icofont-audio:before {
  content: "\eea8";
}
.icofont-automation:before {
  content: "\eea9";
}
.icofont-badge:before {
  content: "\eeaa";
}
.icofont-bag-alt:before {
  content: "\eeab";
}
.icofont-bag:before {
  content: "\eeac";
}
.icofont-ban:before {
  content: "\eead";
}
.icofont-bar-code:before {
  content: "\eeae";
}
.icofont-bars:before {
  content: "\eeaf";
}
.icofont-basket:before {
  content: "\eeb0";
}
.icofont-battery-empty:before {
  content: "\eeb1";
}
.icofont-battery-full:before {
  content: "\eeb2";
}
.icofont-battery-half:before {
  content: "\eeb3";
}
.icofont-battery-low:before {
  content: "\eeb4";
}
.icofont-beaker:before {
  content: "\eeb5";
}
.icofont-beard:before {
  content: "\eeb6";
}
.icofont-bed:before {
  content: "\eeb7";
}
.icofont-bell:before {
  content: "\eeb8";
}
.icofont-beverage:before {
  content: "\eeb9";
}
.icofont-bill:before {
  content: "\eeba";
}
.icofont-bin:before {
  content: "\eebb";
}
.icofont-binary:before {
  content: "\eebc";
}
.icofont-binoculars:before {
  content: "\eebd";
}
.icofont-bluetooth:before {
  content: "\eebe";
}
.icofont-bomb:before {
  content: "\eebf";
}
.icofont-book-mark:before {
  content: "\eec0";
}
.icofont-box:before {
  content: "\eec1";
}
.icofont-briefcase:before {
  content: "\eec2";
}
.icofont-broken:before {
  content: "\eec3";
}
.icofont-bucket:before {
  content: "\eec4";
}
.icofont-bucket1:before {
  content: "\eec5";
}
.icofont-bucket2:before {
  content: "\eec6";
}
.icofont-bug:before {
  content: "\eec7";
}
.icofont-building:before {
  content: "\eec8";
}
.icofont-bulb-alt:before {
  content: "\eec9";
}
.icofont-bullet:before {
  content: "\eeca";
}
.icofont-bullhorn:before {
  content: "\eecb";
}
.icofont-bullseye:before {
  content: "\eecc";
}
.icofont-calendar:before {
  content: "\eecd";
}
.icofont-camera-alt:before {
  content: "\eece";
}
.icofont-camera:before {
  content: "\eecf";
}
.icofont-card:before {
  content: "\eed0";
}
.icofont-cart-alt:before {
  content: "\eed1";
}
.icofont-cart:before {
  content: "\eed2";
}
.icofont-cc:before {
  content: "\eed3";
}
.icofont-charging:before {
  content: "\eed4";
}
.icofont-chat:before {
  content: "\eed5";
}
.icofont-check-alt:before {
  content: "\eed6";
}
.icofont-check-circled:before {
  content: "\eed7";
}
.icofont-check:before {
  content: "\eed8";
}
.icofont-checked:before {
  content: "\eed9";
}
.icofont-children-care:before {
  content: "\eeda";
}
.icofont-clip:before {
  content: "\eedb";
}
.icofont-clock-time:before {
  content: "\eedc";
}
.icofont-close-circled:before {
  content: "\eedd";
}
.icofont-close-line-circled:before {
  content: "\eede";
}
.icofont-close-line-squared-alt:before {
  content: "\eedf";
}
.icofont-close-line-squared:before {
  content: "\eee0";
}
.icofont-close-line:before {
  content: "\eee1";
}
.icofont-close-squared-alt:before {
  content: "\eee2";
}
.icofont-close-squared:before {
  content: "\eee3";
}
.icofont-close:before {
  content: "\eee4";
}
.icofont-cloud-download:before {
  content: "\eee5";
}
.icofont-cloud-refresh:before {
  content: "\eee6";
}
.icofont-cloud-upload:before {
  content: "\eee7";
}
.icofont-cloud:before {
  content: "\eee8";
}
.icofont-code-not-allowed:before {
  content: "\eee9";
}
.icofont-code:before {
  content: "\eeea";
}
.icofont-comment:before {
  content: "\eeeb";
}
.icofont-compass-alt:before {
  content: "\eeec";
}
.icofont-compass:before {
  content: "\eeed";
}
.icofont-computer:before {
  content: "\eeee";
}
.icofont-connection:before {
  content: "\eeef";
}
.icofont-console:before {
  content: "\eef0";
}
.icofont-contacts:before {
  content: "\eef1";
}
.icofont-contrast:before {
  content: "\eef2";
}
.icofont-copyright:before {
  content: "\eef3";
}
.icofont-credit-card:before {
  content: "\eef4";
}
.icofont-crop:before {
  content: "\eef5";
}
.icofont-crown:before {
  content: "\eef6";
}
.icofont-cube:before {
  content: "\eef7";
}
.icofont-cubes:before {
  content: "\eef8";
}
.icofont-dashboard-web:before {
  content: "\eef9";
}
.icofont-dashboard:before {
  content: "\eefa";
}
.icofont-data:before {
  content: "\eefb";
}
.icofont-database-add:before {
  content: "\eefc";
}
.icofont-database-locked:before {
  content: "\eefd";
}
.icofont-database-remove:before {
  content: "\eefe";
}
.icofont-database:before {
  content: "\eeff";
}
.icofont-delete:before {
  content: "\ef00";
}
.icofont-diamond:before {
  content: "\ef01";
}
.icofont-dice-multiple:before {
  content: "\ef02";
}
.icofont-dice:before {
  content: "\ef03";
}
.icofont-disc:before {
  content: "\ef04";
}
.icofont-diskette:before {
  content: "\ef05";
}
.icofont-document-folder:before {
  content: "\ef06";
}
.icofont-download-alt:before {
  content: "\ef07";
}
.icofont-download:before {
  content: "\ef08";
}
.icofont-downloaded:before {
  content: "\ef09";
}
.icofont-drag:before {
  content: "\ef0a";
}
.icofont-drag1:before {
  content: "\ef0b";
}
.icofont-drag2:before {
  content: "\ef0c";
}
.icofont-drag3:before {
  content: "\ef0d";
}
.icofont-earth:before {
  content: "\ef0e";
}
.icofont-ebook:before {
  content: "\ef0f";
}
.icofont-edit:before {
  content: "\ef10";
}
.icofont-eject:before {
  content: "\ef11";
}
.icofont-email:before {
  content: "\ef12";
}
.icofont-envelope-open:before {
  content: "\ef13";
}
.icofont-envelope:before {
  content: "\ef14";
}
.icofont-eraser:before {
  content: "\ef15";
}
.icofont-error:before {
  content: "\ef16";
}
.icofont-excavator:before {
  content: "\ef17";
}
.icofont-exchange:before {
  content: "\ef18";
}
.icofont-exclamation-circle:before {
  content: "\ef19";
}
.icofont-exclamation-square:before {
  content: "\ef1a";
}
.icofont-exclamation-tringle:before {
  content: "\ef1b";
}
.icofont-exclamation:before {
  content: "\ef1c";
}
.icofont-exit:before {
  content: "\ef1d";
}
.icofont-expand:before {
  content: "\ef1e";
}
.icofont-external-link:before {
  content: "\ef1f";
}
.icofont-external:before {
  content: "\ef20";
}
.icofont-eye-alt:before {
  content: "\ef21";
}
.icofont-eye-blocked:before {
  content: "\ef22";
}
.icofont-eye-dropper:before {
  content: "\ef23";
}
.icofont-eye:before {
  content: "\ef24";
}
.icofont-favourite:before {
  content: "\ef25";
}
.icofont-fax:before {
  content: "\ef26";
}
.icofont-file-fill:before {
  content: "\ef27";
}
.icofont-film:before {
  content: "\ef28";
}
.icofont-filter:before {
  content: "\ef29";
}
.icofont-fire-alt:before {
  content: "\ef2a";
}
.icofont-fire-burn:before {
  content: "\ef2b";
}
.icofont-fire:before {
  content: "\ef2c";
}
.icofont-flag-alt-1:before {
  content: "\ef2d";
}
.icofont-flag-alt-2:before {
  content: "\ef2e";
}
.icofont-flag:before {
  content: "\ef2f";
}
.icofont-flame-torch:before {
  content: "\ef30";
}
.icofont-flash-light:before {
  content: "\ef31";
}
.icofont-flash:before {
  content: "\ef32";
}
.icofont-flask:before {
  content: "\ef33";
}
.icofont-focus:before {
  content: "\ef34";
}
.icofont-folder-open:before {
  content: "\ef35";
}
.icofont-folder:before {
  content: "\ef36";
}
.icofont-foot-print:before {
  content: "\ef37";
}
.icofont-garbage:before {
  content: "\ef38";
}
.icofont-gear-alt:before {
  content: "\ef39";
}
.icofont-gear:before {
  content: "\ef3a";
}
.icofont-gears:before {
  content: "\ef3b";
}
.icofont-gift:before {
  content: "\ef3c";
}
.icofont-glass:before {
  content: "\ef3d";
}
.icofont-globe:before {
  content: "\ef3e";
}
.icofont-graffiti:before {
  content: "\ef3f";
}
.icofont-grocery:before {
  content: "\ef40";
}
.icofont-hand:before {
  content: "\ef41";
}
.icofont-hanger:before {
  content: "\ef42";
}
.icofont-hard-disk:before {
  content: "\ef43";
}
.icofont-heart-alt:before {
  content: "\ef44";
}
.icofont-heart:before {
  content: "\ef45";
}
.icofont-history:before {
  content: "\ef46";
}
.icofont-home:before {
  content: "\ef47";
}
.icofont-horn:before {
  content: "\ef48";
}
.icofont-hour-glass:before {
  content: "\ef49";
}
.icofont-id:before {
  content: "\ef4a";
}
.icofont-image:before {
  content: "\ef4b";
}
.icofont-inbox:before {
  content: "\ef4c";
}
.icofont-infinite:before {
  content: "\ef4d";
}
.icofont-info-circle:before {
  content: "\ef4e";
}
.icofont-info-square:before {
  content: "\ef4f";
}
.icofont-info:before {
  content: "\ef50";
}
.icofont-institution:before {
  content: "\ef51";
}
.icofont-interface:before {
  content: "\ef52";
}
.icofont-invisible:before {
  content: "\ef53";
}
.icofont-jacket:before {
  content: "\ef54";
}
.icofont-jar:before {
  content: "\ef55";
}
.icofont-jewlery:before {
  content: "\ef56";
}
.icofont-karate:before {
  content: "\ef57";
}
.icofont-key-hole:before {
  content: "\ef58";
}
.icofont-key:before {
  content: "\ef59";
}
.icofont-label:before {
  content: "\ef5a";
}
.icofont-lamp:before {
  content: "\ef5b";
}
.icofont-layers:before {
  content: "\ef5c";
}
.icofont-layout:before {
  content: "\ef5d";
}
.icofont-leaf:before {
  content: "\ef5e";
}
.icofont-leaflet:before {
  content: "\ef5f";
}
.icofont-learn:before {
  content: "\ef60";
}
.icofont-lego:before {
  content: "\ef61";
}
.icofont-lens:before {
  content: "\ef62";
}
.icofont-letter:before {
  content: "\ef63";
}
.icofont-letterbox:before {
  content: "\ef64";
}
.icofont-library:before {
  content: "\ef65";
}
.icofont-license:before {
  content: "\ef66";
}
.icofont-life-bouy:before {
  content: "\ef67";
}
.icofont-life-buoy:before {
  content: "\ef68";
}
.icofont-life-jacket:before {
  content: "\ef69";
}
.icofont-life-ring:before {
  content: "\ef6a";
}
.icofont-light-bulb:before {
  content: "\ef6b";
}
.icofont-lighter:before {
  content: "\ef6c";
}
.icofont-lightning-ray:before {
  content: "\ef6d";
}
.icofont-like:before {
  content: "\ef6e";
}
.icofont-line-height:before {
  content: "\ef6f";
}
.icofont-link-alt:before {
  content: "\ef70";
}
.icofont-link:before {
  content: "\ef71";
}
.icofont-list:before {
  content: "\ef72";
}
.icofont-listening:before {
  content: "\ef73";
}
.icofont-listine-dots:before {
  content: "\ef74";
}
.icofont-listing-box:before {
  content: "\ef75";
}
.icofont-listing-number:before {
  content: "\ef76";
}
.icofont-live-support:before {
  content: "\ef77";
}
.icofont-location-arrow:before {
  content: "\ef78";
}
.icofont-location-pin:before {
  content: "\ef79";
}
.icofont-lock:before {
  content: "\ef7a";
}
.icofont-login:before {
  content: "\ef7b";
}
.icofont-logout:before {
  content: "\ef7c";
}
.icofont-lollipop:before {
  content: "\ef7d";
}
.icofont-long-drive:before {
  content: "\ef7e";
}
.icofont-look:before {
  content: "\ef7f";
}
.icofont-loop:before {
  content: "\ef80";
}
.icofont-luggage:before {
  content: "\ef81";
}
.icofont-lunch:before {
  content: "\ef82";
}
.icofont-lungs:before {
  content: "\ef83";
}
.icofont-magic-alt:before {
  content: "\ef84";
}
.icofont-magic:before {
  content: "\ef85";
}
.icofont-magnet:before {
  content: "\ef86";
}
.icofont-mail-box:before {
  content: "\ef87";
}
.icofont-mail:before {
  content: "\ef88";
}
.icofont-male:before {
  content: "\ef89";
}
.icofont-map-pins:before {
  content: "\ef8a";
}
.icofont-map:before {
  content: "\ef8b";
}
.icofont-maximize:before {
  content: "\ef8c";
}
.icofont-measure:before {
  content: "\ef8d";
}
.icofont-medicine:before {
  content: "\ef8e";
}
.icofont-mega-phone:before {
  content: "\ef8f";
}
.icofont-megaphone-alt:before {
  content: "\ef90";
}
.icofont-megaphone:before {
  content: "\ef91";
}
.icofont-memorial:before {
  content: "\ef92";
}
.icofont-memory-card:before {
  content: "\ef93";
}
.icofont-mic-mute:before {
  content: "\ef94";
}
.icofont-mic:before {
  content: "\ef95";
}
.icofont-military:before {
  content: "\ef96";
}
.icofont-mill:before {
  content: "\ef97";
}
.icofont-minus-circle:before {
  content: "\ef98";
}
.icofont-minus-square:before {
  content: "\ef99";
}
.icofont-minus:before {
  content: "\ef9a";
}
.icofont-mobile-phone:before {
  content: "\ef9b";
}
.icofont-molecule:before {
  content: "\ef9c";
}
.icofont-money:before {
  content: "\ef9d";
}
.icofont-moon:before {
  content: "\ef9e";
}
.icofont-mop:before {
  content: "\ef9f";
}
.icofont-muffin:before {
  content: "\efa0";
}
.icofont-mustache:before {
  content: "\efa1";
}
.icofont-navigation-menu:before {
  content: "\efa2";
}
.icofont-navigation:before {
  content: "\efa3";
}
.icofont-network-tower:before {
  content: "\efa4";
}
.icofont-network:before {
  content: "\efa5";
}
.icofont-news:before {
  content: "\efa6";
}
.icofont-newspaper:before {
  content: "\efa7";
}
.icofont-no-smoking:before {
  content: "\efa8";
}
.icofont-not-allowed:before {
  content: "\efa9";
}
.icofont-notebook:before {
  content: "\efaa";
}
.icofont-notepad:before {
  content: "\efab";
}
.icofont-notification:before {
  content: "\efac";
}
.icofont-numbered:before {
  content: "\efad";
}
.icofont-opposite:before {
  content: "\efae";
}
.icofont-optic:before {
  content: "\efaf";
}
.icofont-options:before {
  content: "\efb0";
}
.icofont-package:before {
  content: "\efb1";
}
.icofont-page:before {
  content: "\efb2";
}
.icofont-paint:before {
  content: "\efb3";
}
.icofont-paper-plane:before {
  content: "\efb4";
}
.icofont-paperclip:before {
  content: "\efb5";
}
.icofont-papers:before {
  content: "\efb6";
}
.icofont-pay:before {
  content: "\efb7";
}
.icofont-penguin-linux:before {
  content: "\efb8";
}
.icofont-pestle:before {
  content: "\efb9";
}
.icofont-phone-circle:before {
  content: "\efba";
}
.icofont-phone:before {
  content: "\efbb";
}
.icofont-picture:before {
  content: "\efbc";
}
.icofont-pine:before {
  content: "\efbd";
}
.icofont-pixels:before {
  content: "\efbe";
}
.icofont-plugin:before {
  content: "\efbf";
}
.icofont-plus-circle:before {
  content: "\efc0";
}
.icofont-plus-square:before {
  content: "\efc1";
}
.icofont-plus:before {
  content: "\efc2";
}
.icofont-polygonal:before {
  content: "\efc3";
}
.icofont-power:before {
  content: "\efc4";
}
.icofont-price:before {
  content: "\efc5";
}
.icofont-print:before {
  content: "\efc6";
}
.icofont-puzzle:before {
  content: "\efc7";
}
.icofont-qr-code:before {
  content: "\efc8";
}
.icofont-queen:before {
  content: "\efc9";
}
.icofont-question-circle:before {
  content: "\efca";
}
.icofont-question-square:before {
  content: "\efcb";
}
.icofont-question:before {
  content: "\efcc";
}
.icofont-quote-left:before {
  content: "\efcd";
}
.icofont-quote-right:before {
  content: "\efce";
}
.icofont-random:before {
  content: "\efcf";
}
.icofont-recycle:before {
  content: "\efd0";
}
.icofont-refresh:before {
  content: "\efd1";
}
.icofont-repair:before {
  content: "\efd2";
}
.icofont-reply-all:before {
  content: "\efd3";
}
.icofont-reply:before {
  content: "\efd4";
}
.icofont-resize:before {
  content: "\efd5";
}
.icofont-responsive:before {
  content: "\efd6";
}
.icofont-retweet:before {
  content: "\efd7";
}
.icofont-road:before {
  content: "\efd8";
}
.icofont-robot:before {
  content: "\efd9";
}
.icofont-royal:before {
  content: "\efda";
}
.icofont-rss-feed:before {
  content: "\efdb";
}
.icofont-safety:before {
  content: "\efdc";
}
.icofont-sale-discount:before {
  content: "\efdd";
}
.icofont-satellite:before {
  content: "\efde";
}
.icofont-send-mail:before {
  content: "\efdf";
}
.icofont-server:before {
  content: "\efe0";
}
.icofont-settings-alt:before {
  content: "\efe1";
}
.icofont-settings:before {
  content: "\efe2";
}
.icofont-share-alt:before {
  content: "\efe3";
}
.icofont-share-boxed:before {
  content: "\efe4";
}
.icofont-share:before {
  content: "\efe5";
}
.icofont-shield:before {
  content: "\efe6";
}
.icofont-shopping-cart:before {
  content: "\efe7";
}
.icofont-sign-in:before {
  content: "\efe8";
}
.icofont-sign-out:before {
  content: "\efe9";
}
.icofont-signal:before {
  content: "\efea";
}
.icofont-site-map:before {
  content: "\efeb";
}
.icofont-smart-phone:before {
  content: "\efec";
}
.icofont-soccer:before {
  content: "\efed";
}
.icofont-sort-alt:before {
  content: "\efee";
}
.icofont-sort:before {
  content: "\efef";
}
.icofont-space:before {
  content: "\eff0";
}
.icofont-spanner:before {
  content: "\eff1";
}
.icofont-speech-comments:before {
  content: "\eff2";
}
.icofont-speed-meter:before {
  content: "\eff3";
}
.icofont-spinner-alt-1:before {
  content: "\eff4";
}
.icofont-spinner-alt-2:before {
  content: "\eff5";
}
.icofont-spinner-alt-3:before {
  content: "\eff6";
}
.icofont-spinner-alt-4:before {
  content: "\eff7";
}
.icofont-spinner-alt-5:before {
  content: "\eff8";
}
.icofont-spinner-alt-6:before {
  content: "\eff9";
}
.icofont-spinner:before {
  content: "\effa";
}
.icofont-spreadsheet:before {
  content: "\effb";
}
.icofont-square:before {
  content: "\effc";
}
.icofont-ssl-security:before {
  content: "\effd";
}
.icofont-star-alt-1:before {
  content: "\effe";
}
.icofont-star-alt-2:before {
  content: "\efff";
}
.icofont-star:before {
  content: "\f000";
}
.icofont-street-view:before {
  content: "\f001";
}
.icofont-support-faq:before {
  content: "\f002";
}
.icofont-tack-pin:before {
  content: "\f003";
}
.icofont-tag:before {
  content: "\f004";
}
.icofont-tags:before {
  content: "\f005";
}
.icofont-tasks-alt:before {
  content: "\f006";
}
.icofont-tasks:before {
  content: "\f007";
}
.icofont-telephone:before {
  content: "\f008";
}
.icofont-telescope:before {
  content: "\f009";
}
.icofont-terminal:before {
  content: "\f00a";
}
.icofont-thumbs-down:before {
  content: "\f00b";
}
.icofont-thumbs-up:before {
  content: "\f00c";
}
.icofont-tick-boxed:before {
  content: "\f00d";
}
.icofont-tick-mark:before {
  content: "\f00e";
}
.icofont-ticket:before {
  content: "\f00f";
}
.icofont-tie:before {
  content: "\f010";
}
.icofont-toggle-off:before {
  content: "\f011";
}
.icofont-toggle-on:before {
  content: "\f012";
}
.icofont-tools-alt-2:before {
  content: "\f013";
}
.icofont-tools:before {
  content: "\f014";
}
.icofont-touch:before {
  content: "\f015";
}
.icofont-traffic-light:before {
  content: "\f016";
}
.icofont-transparent:before {
  content: "\f017";
}
.icofont-tree:before {
  content: "\f018";
}
.icofont-unique-idea:before {
  content: "\f019";
}
.icofont-unlock:before {
  content: "\f01a";
}
.icofont-unlocked:before {
  content: "\f01b";
}
.icofont-upload-alt:before {
  content: "\f01c";
}
.icofont-upload:before {
  content: "\f01d";
}
.icofont-usb-drive:before {
  content: "\f01e";
}
.icofont-usb:before {
  content: "\f01f";
}
.icofont-vector-path:before {
  content: "\f020";
}
.icofont-verification-check:before {
  content: "\f021";
}
.icofont-wall-clock:before {
  content: "\f022";
}
.icofont-wall:before {
  content: "\f023";
}
.icofont-wallet:before {
  content: "\f024";
}
.icofont-warning-alt:before {
  content: "\f025";
}
.icofont-warning:before {
  content: "\f026";
}
.icofont-water-drop:before {
  content: "\f027";
}
.icofont-web:before {
  content: "\f028";
}
.icofont-wheelchair:before {
  content: "\f029";
}
.icofont-wifi-alt:before {
  content: "\f02a";
}
.icofont-wifi:before {
  content: "\f02b";
}
.icofont-world:before {
  content: "\f02c";
}
.icofont-zigzag:before {
  content: "\f02d";
}
.icofont-zipped:before {
  content: "\f02e";
}
.icofont-xs {
  font-size: 0.5em;
}
.icofont-sm {
  font-size: 0.75em;
}
.icofont-md {
  font-size: 1.25em;
}
.icofont-lg {
  font-size: 1.5em;
}
.icofont-1x {
  font-size: 1em;
}
.icofont-2x {
  font-size: 2em;
}
.icofont-3x {
  font-size: 3em;
}
.icofont-4x {
  font-size: 4em;
}
.icofont-5x {
  font-size: 5em;
}
.icofont-6x {
  font-size: 6em;
}
.icofont-7x {
  font-size: 7em;
}
.icofont-8x {
  font-size: 8em;
}
.icofont-9x {
  font-size: 9em;
}
.icofont-10x {
  font-size: 10em;
}
.icofont-fw {
  text-align: center;
  width: 1.25em;
}
.icofont-ul {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}
.icofont-ul > li {
  position: relative;
  line-height: 2em;
}
.icofont-ul > li .icofont {
  display: inline-block;
  vertical-align: middle;
}
.icofont-border {
  border: solid 0.08em #f1f1f1;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}
.icofont-pull-left {
  float: left;
}
.icofont-pull-right {
  float: right;
}
.icofont.icofont-pull-left {
  margin-right: 0.3em;
}
.icofont.icofont-pull-right {
  margin-left: 0.3em;
}
.icofont-spin {
  -webkit-animation: icofont-spin 2s infinite linear;
  animation: icofont-spin 2s infinite linear;
  display: inline-block;
}
.icofont-pulse {
  -webkit-animation: icofont-spin 1s infinite steps(8);
  animation: icofont-spin 1s infinite steps(8);
  display: inline-block;
}
@-webkit-keyframes icofont-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes icofont-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icofont-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icofont-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icofont-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icofont-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icofont-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}
.icofont-flip-horizontal.icofont-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}
:root .icofont-rotate-90,
:root .icofont-rotate-180,
:root .icofont-rotate-270,
:root .icofont-flip-horizontal,
:root .icofont-flip-vertical {
  -webkit-filter: none;
  filter: none;
  display: inline-block;
}
.icofont-inverse {
  color: #fff;
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
:root {
  --dark-color: #323a45;
  --color-fff: #ffffff;
  --color-100: #f0f0f0;
  --color-200: #eeeeee;
  --color-300: #e0e0e0;
  --color-400: #bdbdbd;
  --color-500: #555555;
  --color-600: #757575;
  --color-700: #616161;
  --color-800: #424242;
  --color-900: #212121;
  --color-000: #000000;
  --border-color: #f0f0f0;
  --card-color: #ffffff;
  --body-color: #f9fbfd;
  --white-color: #ffffff;
  --sidebar-color: #fcfcfc;
  --text-color: #212529;
  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0;
}
[data-theme="dark"] {
  --dark-color: #323a45;
  --color-fff: #000000;
  --color-100: #212121;
  --color-200: #262727;
  --color-300: #616161;
  --color-400: #757575;
  --color-500: #8f8f8f;
  --color-600: #bdbdbd;
  --color-700: #e0e0e0;
  --color-800: #eeeeee;
  --color-900: #f0f0f0;
  --color-000: #ffffff;
  --border-color: #353535;
  --card-color: #262727;
  --body-color: #1e1f20;
  --white-color: #ffffff;
  --sidebar-color: #fcfcfc;
  --text-color: #8f8f8f;
  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0;
}
[data-theme="dark"] [class="theme-indigo"] {
  --secondary-color: #f19828;
  --body-color: #1e1f20;
  --border-color: #252525;
  --card-color: #111111;
  --primary-gradient: linear-gradient(45deg, #f5558d, #ff1843);
}
[class="theme-indigo"] {
  --primary-color: #5a17d4;
  --secondary-color: #f19828;
  --chart-color1: #484c7f;
  --chart-color2: #f19828;
  --chart-color3: #f5558d;
  --chart-color4: #a7daff;
  --chart-color5: #9cbf3b;
  --chart-color6: #a0c1b8;
  --chart-color7: #64868e;
  --primary-gradient: linear-gradient(45deg, #f5558d, #ff1843);
}
.secondary-color {
  color: var(--secondary-color);
}
.secondary-color-fill {
  fill: var(--secondary-color);
}
body {
  -moz-transition: ease 0.1s;
  -o-transition: ease 0.1s;
  -webkit-transition: ease 0.1s;
  transition: ease 0.1s;
  background-color: var(--body-color);
  color: var(--text-color);
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
@media only screen and (max-width: 554px) {
  body {
    font-size: 12px;
  }
}
body.font-opensans {
  font-family: "Open Sans", sans-serif;
}
body.font-quicksand {
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
}
body.font-nunito {
  font-family: "Nunito", sans-serif;
  font-size: 15px;
}
body.font-raleway {
  font-family: "Raleway", sans-serif;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: var(--secondary-color);
}
@media only screen and (max-width: 554px) {
  .small-14 {
    font-size: 13px;
  }
}
.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--color-300);
}
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  top: 50%;
  background-color: var(--primary-color);
  transform: translateY(-50%);
}
table.dataTable > tbody > tr.child ul.dtr-details {
  display: block;
}
@media only screen and (max-width: 767px) {
  #myProjectTable_length,
  #myProjectTable_filter,
  #myProjectTable_info,
  #myTransaction_length,
  #myTransaction_filter,
  #myTransaction_info,
  #patient-table_length,
  #patient-table_filter,
  #patient-table_info {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  #myProjectTable_paginate ul.pagination,
  #myTransaction_paginate ul.pagination,
  #patient-table_paginate ul.pagination {
    justify-content: start !important;
  }
}
@media only screen and (max-width: 375px) {
  table.dataTable > tbody > tr.child ul.dtr-details .dtr-data .avatar-list {
    display: inline;
  }
}
@media only screen and (max-width: 375px) {
  .res-set .text-truncate .small {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .intime span {
    font-size: 12px;
  }
}
.mem-list {
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 220px;
  overflow-y: auto;
}
.mem-list::-webkit-scrollbar {
  width: 0;
}
@media only screen and (max-width: 375px) {
  .mem-list h6,
  .mem-list .h6 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 375px) {
  .mem-list .timeline-item strong {
    font-size: 13px;
  }
}
.todo_list li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}
.todo_list li:last-child {
  border-bottom: 0;
}
.todo_list li label {
  position: relative;
}
.todo_list li label:before {
  position: absolute;
  content: "";
  background-color: var(--secondary-color);
  width: 25px;
  height: 25px;
  left: 0px;
  top: 0px;
  z-index: 1;
  border-radius: 0.25rem;
}
.todo_list li label input {
  position: relative;
  z-index: 9;
  font-size: 16px;
  margin: 5px;
}
.todo_list li label input.form-check-input {
  background-color: rgba(var(--secondary-color), 0.5);
  border-color: var(--secondary-color);
  box-shadow: none;
}
.todo_list li label span {
  margin-left: 10px;
}
.todo_list .custom-checkbox input[type="checkbox"]:checked + span {
  text-decoration: line-through;
}
.brand-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.brand-icon .logo-icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  background-color: var(--white-color);
  margin: inherit;
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand-icon .logo-icon svg {
  width: 50px;
}
.brand-icon .logo-icon svg path {
  fill: var(--primary-color);
}
.brand-icon .logo-text {
  font-weight: bold;
  color: var(--white-color);
  font-size: 18px;
  display: inline-block;
  padding-left: 15px;
}
.pulse .pulse-ring {
  border-color: var(--primary-color);
  display: block;
  position: absolute;
  -webkit-animation: animation-pulse 3.5s ease-out;
  animation: animation-pulse 3.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-style: solid;
  top: 0;
  left: 3px;
  border-radius: 45px;
  height: 45px;
  width: 45px;
  opacity: 0;
  border-width: 5px;
}
.pulse::after {
  display: none;
}
@-webkit-keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@media only screen and (max-width: 554px) {
  .btn-set-task {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 480px) {
  .mx-size {
    max-width: 320px;
  }
}
.tikit-info i {
  line-height: 0;
}
.tikit-info span {
  line-height: 25px;
}
a {
  color: var(--text-color);
}
@media only screen and (max-width: 390px) {
  .project-tab {
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 554px) {
  .project-tab .prtab-set {
    margin-left: 0px !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .project-tab .prtab-set .nav-link {
    padding: 0.5rem 18px;
  }
}
@media only screen and (max-width: 380px) {
  .project-tab .prtab-set .nav-link {
    padding: 0.5rem 10px;
  }
}
@media only screen and (max-width: 390px) {
  .invoice-set.nav-tabs .nav-link {
    width: 100px;
    text-align: center;
    font-size: 14px;
  }
}
@media only screen and (max-width: 320px) {
  .invoice-set.nav-tabs .nav-link {
    width: 98px;
    text-align: center;
  }
}
@media only screen and (max-width: 380px) {
  #my_calendar .fc .fc-toolbar-title {
    font-size: 14px;
  }
}
#apex-simple-donut .apexcharts-canvas {
  margin: 0 auto;
}
@media only screen and (max-width: 991px) {
  .card-chat.open {
    top: 0;
    z-index: 99999;
  }
}
.form-control#datepicker,
.form-control#timepicker,
.form-control#datepicker2,
.form-control#datepicker3,
.form-control#timepicker2,
.form-control#timepicker3 {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.notifications {
  z-index: 9999 !important;
}
.notifications .pulse,
.notifications .pulse:focus {
  color: var(--text-color) !important;
}
.notifications .card .card-header {
  background-color: var(--primary-color);
  color: #fff;
}
.notifications .card .card-header .nav-tabs .nav-link {
  color: rgba(255, 255, 255, 0.2);
  font-size: 1rem;
  border: 0;
}
.notifications .card .card-header .nav-tabs .nav-link.active,
.notifications .card .card-header .nav-tabs .nav-link:hover {
  color: var(--secondary-color);
  background-color: transparent;
}
.notifications .card .card-header .nav-tabs .nav-link.active::after,
.notifications .card .card-header .nav-tabs .nav-link:hover::after {
  content: "";
  width: 7px;
  height: 7px;
  background-color: var(--secondary-color);
  display: block;
  border-radius: 50%;
  margin: 5px auto 0;
}
.notifications .card .list {
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 330px;
  overflow-y: auto;
}
.notifications .card .list::-webkit-scrollbar {
  width: 0;
}
@media only screen and (max-width: 767px) {
  .notifications .dropdown-menu {
    width: 280px;
  }
}
@media only screen and (max-width: 320px) {
  .notifications .dropdown-menu {
    width: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .notifications .dropdown-menu .w380 {
    width: 280px;
  }
}
@media only screen and (max-width: 320px) {
  .notifications .dropdown-menu .w380 {
    width: 220px;
  }
}
.row-deck > .col,
.row-deck > [class*="col-"] {
  display: flex;
  align-items: stretch;
}
.row-deck > .col .card,
.row-deck > [class*="col-"] .card {
  flex: 1 1 auto;
}
.apexcharts-canvas line {
  stroke: var(--border-color);
}
.apexcharts-canvas text {
  fill: var(--color-500);
}
.apexcharts-canvas circle {
  stroke: var(--border-color);
}
.apexcharts-canvas .apexcharts-legend-text {
  color: var(--color-500) !important;
}
.sparkline_chart canvas {
  width: 100% !important;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: var(--color-100) !important;
}
code[class*="language-"],
pre[class*="language-"] {
  text-shadow: none !important;
  color: var(--color-000) !important;
}
.side-navbar li {
  margin-bottom: 5px;
}
.side-navbar li a {
  color: var(--text-color);
}
.btn-outline-secondary.btn-block {
  color: #fff !important;
}
@media only screen and (max-width: 390px) {
  .teacher-fulldeatil {
    flex-direction: column;
  }
}
@media only screen and (max-width: 390px) {
  .teacher-fulldeatil .teacher-info.border-start {
    border-left: 0px !important;
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 390px) {
  .teacher-fulldeatil .profile-av {
    text-align: left !important;
  }
}
@media only screen and (max-width: 390px) {
  .teacher-fulldeatil .about-info {
    justify-content: flex-start !important;
  }
}
.navbar-toggler:focus {
  box-shadow: none;
}
@media only screen and (max-width: 1199px) {
  .set-md-img {
    max-width: 225px;
  }
}
.line-color .apexcharts-canvas line {
  stroke: var(--card-color) !important;
}
@media only screen and (max-width: 390px) {
  .shared-table .nav-link {
    padding: 5px 12px;
  }
}
@media only screen and (max-width: 768px) {
  .form-control-lg {
    min-height: calc(1.5em + 1rem + 2px) !important;
  }
}
.project_name {
  position: absolute;
  left: 50%;
  top: 33px;
  transform: translateX(-50%);
}
.project-block {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  color: var(--text-color);
  font-size: 30px;
}
#apex-emplyoeeAnalytics .apexcharts-canvas line {
  stroke: transparent !important;
}
#apex-MainCategories .apexcharts-canvas,
#incomeanalytics .apexcharts-canvas {
  margin: 0 auto;
}
.ico-title {
  font-size: 2em;
}
.iconlist {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.iconlist .icon-holder {
  position: relative;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
  padding-bottom: 5px;
  border: 1px solid var(--border-color);
  transition: all 0.2s linear 0s;
}
.iconlist .icon-holder:hover {
  background: var(--secondary-color);
  color: #ffffff;
}
.iconlist .icon-holder:hover .icon i {
  color: #ffffff;
}
.iconlist .icon-holder .icon {
  padding: 20px;
  text-align: center;
}
.iconlist .icon-holder .icon i {
  font-size: 3em;
  color: var(--font-color);
}
.iconlist .icon-holder span {
  font-size: 14px;
  display: block;
  margin-top: 5px;
  border-radius: 3px;
}
.sidebar-mini .note-block {
  display: none;
}
@media only screen and (max-width: 767px) {
  .fc-daygrid-event {
    display: none;
  }
}
@media only screen and (max-width: 375px) {
  .fc-header-toolbar {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 375px) {
  .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .role-form h3,
  .role-form .h3 {
    font-size: 12px;
  }
}
@media only screen and (max-width: 554px) {
  .w-sm-100 {
    width: 100%;
  }
}
@media only screen and (max-width: 430px) {
  .profile-teacher {
    margin: 0 auto;
    padding-right: 0px !important;
  }
  .teacher-card .card-body {
    flex-direction: column;
  }
  .teacher-card .card-body .profile-av {
    padding-right: 0px !important;
  }
  .teacher-card .card-body .profile-av.w220 {
    width: 100%;
  }
  .teacher-card .card-body .profile-av .about-info {
    justify-content: center !important;
  }
  .teacher-card .card-body .teacher-info {
    padding-left: 0px !important;
    border-color: transparent !important;
    text-align: center;
  }
  .teacher-card .card-body .teacher-info .social {
    justify-content: center;
  }
  .teacher-card .card-body .teacher-info .ct-btn-set {
    justify-content: center;
  }
}
@media only screen and (max-width: 390px) {
  .bunch_list {
    flex-direction: column;
  }
  .bunch_list .task_status,
  .bunch_list .lesson_name,
  .bunch_list .assignment_name,
  .bunch_list .submit_time,
  .bunch_list .complited_time {
    margin: 3px 0px;
  }
}
body.rtl_mode {
  direction: rtl;
  text-align: right;
}
body.rtl_mode ul {
  padding-right: 0;
}
body.rtl_mode .float-right {
  float: left !important;
}
body.rtl_mode .text-end {
  text-align: left !important;
}
body.rtl_mode .ms-auto {
  margin-left: unset !important;
  margin-right: auto !important;
}
body.rtl_mode .me-auto {
  margin-right: unset !important;
  margin-left: auto !important;
}
body.rtl_mode .end-0 {
  left: 0 !important;
  right: auto !important;
}
body.rtl_mode .ms-1 {
  margin-right: 0.25rem !important;
}
body.rtl_mode .ms-1,
body.rtl_mode .ms-sm-1,
body.rtl_mode .ms-md-1,
body.rtl_mode .ms-lg-1,
body.rtl_mode .ms-xl-1,
body.rtl_mode .ms-xxl-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}
body.rtl_mode .ms-2,
body.rtl_mode .ms-sm-2,
body.rtl_mode .ms-md-2,
body.rtl_mode .ms-lg-2,
body.rtl_mode .ms-xl-2,
body.rtl_mode .ms-xxl-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}
body.rtl_mode .ms-3,
body.rtl_mode .ms-sm-3,
body.rtl_mode .ms-md-3,
body.rtl_mode .ms-lg-3,
body.rtl_mode .ms-xl-3,
body.rtl_mode .ms-xxl-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}
body.rtl_mode .ms-4,
body.rtl_mode .ms-sm-4,
body.rtl_mode .ms-md-4,
body.rtl_mode .ms-lg-4,
body.rtl_mode .ms-xl-4,
body.rtl_mode .ms-xxl-5 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}
body.rtl_mode .ms-5,
body.rtl_mode .ms-sm-5,
body.rtl_mode .ms-md-5,
body.rtl_mode .ms-lg-5,
body.rtl_mode .ms-xl-5,
body.rtl_mode .ms-xxl-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}
body.rtl_mode .me-1,
body.rtl_mode .me-sm-1,
body.rtl_mode .me-md-1,
body.rtl_mode .me-lg-1,
body.rtl_mode .me-xl-1,
body.rtl_mode .me-xxl-1 {
  margin-left: 0.25rem !important;
  margin-right: 0 !important;
}
body.rtl_mode .me-2,
body.rtl_mode .me-sm-2,
body.rtl_mode .me-md-2,
body.rtl_mode .me-lg-2,
body.rtl_mode .me-xl-2,
body.rtl_mode .me-xxl-2 {
  margin-left: 0.5rem !important;
  margin-right: 0 !important;
}
body.rtl_mode .me-3,
body.rtl_mode .me-sm-3,
body.rtl_mode .me-md-3,
body.rtl_mode .me-lg-3,
body.rtl_mode .me-xl-3,
body.rtl_mode .me-xxl-3 {
  margin-left: 1rem !important;
  margin-right: 0 !important;
}
body.rtl_mode .me-4,
body.rtl_mode .me-sm-4,
body.rtl_mode .me-md-4,
body.rtl_mode .me-lg-4,
body.rtl_mode .me-xl-4,
body.rtl_mode .me-xxl-4 {
  margin-left: 1.5rem !important;
  margin-right: 0 !important;
}
body.rtl_mode .me-5,
body.rtl_mode .me-sm-5,
body.rtl_mode .me-md-5,
body.rtl_mode .me-lg-5,
body.rtl_mode .me-xl-5,
body.rtl_mode .me-xxl-5 {
  margin-left: 3rem !important;
  margin-right: 0 !important;
}
body.rtl_mode .ps-1,
body.rtl_mode .ps-sm-1,
body.rtl_mode .ps-md-1,
body.rtl_mode .ps-lg-1,
body.rtl_mode .ps-xl-1,
body.rtl_mode .ps-xxl-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}
body.rtl_mode .ps-2,
body.rtl_mode .ps-sm-2,
body.rtl_mode .ps-md-2,
body.rtl_mode .ps-lg-2,
body.rtl_mode .ps-xl-2,
body.rtl_mode .ps-xxl-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}
body.rtl_mode .ps-3,
body.rtl_mode .ps-sm-3,
body.rtl_mode .ps-md-3,
body.rtl_mode .ps-lg-3,
body.rtl_mode .ps-xl-3,
body.rtl_mode .ps-xxl-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}
body.rtl_mode .ps-4,
body.rtl_mode .ps-sm-4,
body.rtl_mode .ps-md-4,
body.rtl_mode .ps-lg-4,
body.rtl_mode .ps-xl-4,
body.rtl_mode .ps-xxl-5 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}
body.rtl_mode .ps-5,
body.rtl_mode .ps-sm-5,
body.rtl_mode .ps-md-5,
body.rtl_mode .ps-lg-5,
body.rtl_mode .ps-xl-5,
body.rtl_mode .ps-xxl-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}
body.rtl_mode .pe-1,
body.rtl_mode .pe-sm-1,
body.rtl_mode .pe-md-1,
body.rtl_mode .pe-lg-1,
body.rtl_mode .pe-xl-1,
body.rtl_mode .pe-xxl-1 {
  padding-left: 0.25rem !important;
  padding-right: 0 !important;
}
body.rtl_mode .pe-2,
body.rtl_mode .pe-sm-2,
body.rtl_mode .pe-md-2,
body.rtl_mode .pe-lg-2,
body.rtl_mode .pe-xl-2,
body.rtl_mode .pe-xxl-2 {
  padding-left: 0.5rem !important;
  padding-right: 0 !important;
}
body.rtl_mode .pe-3,
body.rtl_mode .pe-sm-3,
body.rtl_mode .pe-md-3,
body.rtl_mode .pe-lg-3,
body.rtl_mode .pe-xl-3,
body.rtl_mode .pe-xxl-3 {
  padding-left: 1rem !important;
  padding-right: 0 !important;
}
body.rtl_mode .pe-4,
body.rtl_mode .pe-sm-4,
body.rtl_mode .pe-md-4,
body.rtl_mode .pe-lg-4,
body.rtl_mode .pe-xl-4,
body.rtl_mode .pe-xxl-4 {
  padding-left: 1.5rem !important;
  padding-right: 0 !important;
}
body.rtl_mode .pe-5,
body.rtl_mode .pe-sm-5,
body.rtl_mode .pe-md-5,
body.rtl_mode .pe-lg-5,
body.rtl_mode .pe-xl-5,
body.rtl_mode .pe-xxl-5 {
  padding-left: 3rem !important;
  padding-right: 0 !important;
}
body.rtl_mode .border-start {
  border-left: 0px !important;
  border-right: 1px solid var(--border-color) !important;
}
body.rtl_mode .border-end {
  border-left: 1px solid var(--border-color) !important;
  border-right: 0px !important;
}
body.rtl_mode .dropdown-menu {
  text-align: right;
}
body.rtl_mode .dropdown-menu-right {
  left: 0;
  right: auto;
  text-align: right;
}
body.rtl_mode .modal-header .btn-close {
  margin: unset;
}
body.rtl_mode .list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
  margin-right: 0;
}
body.rtl_mode .form-check {
  padding-right: 1.5em;
  padding-left: 0;
}
body.rtl_mode .form-check .form-check-input {
  float: right;
  margin-left: 10px;
  margin-right: -1.5em;
}
body.rtl_mode .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}
body.rtl_mode .breadcrumb-item + .breadcrumb-item:before {
  float: right;
  padding-left: 0.5rem;
  padding-right: 0;
}
body.rtl_mode .modal.fade .modal-dialog-vertical {
  transform: translateX(100%);
}
body.rtl_mode .modal.show .modal-dialog-vertical {
  transform: translateX(0);
}
body.rtl_mode
  .input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
body.rtl_mode
  .input-group:not(.has-validation)
  > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
body.rtl_mode
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
body.rtl_mode .btn-group .btn:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
body.rtl_mode .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
body.rtl_mode .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
body.rtl_mode .btn-group > .btn:nth-child(n + 3),
body.rtl_mode .btn-group > :not(.btn-check) + .btn,
body.rtl_mode .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
body.rtl_mode .sidebar-mini-btn .fa-arrow-left {
  transform: rotate(180deg);
}
body.rtl_mode .dropdown-toggle::after {
  margin-left: unset;
  margin-right: 0.255em;
}
body.rtl_mode div.dataTables_wrapper div.dataTables_filter {
  text-align: end;
}
@media (min-width: 992px) {
  body.rtl_mode .me-lg-0 {
    margin-left: 0 !important;
  }
  body.rtl_mode .pe-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  body.rtl_mode .ms-md-5 {
    margin-right: 3rem !important;
  }
  body.rtl_mode .text-md-start {
    text-align: right !important;
  }
}
body.rtl_mode .timeline-item {
  border-left: 0;
  border-right: 2px solid var(--border-color);
}
body.rtl_mode .timeline-item:after {
  right: -5px;
  left: auto;
}
body.rtl_mode .apexcharts-canvas,
body.rtl_mode .owl-carousel {
  direction: ltr;
}
body.rtl_mode .owl-carousel {
  text-align: left;
}
body.rtl_mode .owl-carousel .ms-2 {
  margin-left: 0.5rem !important;
}
body.rtl_mode .sidebar {
  margin-left: 0px !important;
  margin-right: 25px !important;
}
body.rtl_mode .sidebar.sidebar-mini .form-switch.theme-rtl {
  padding-left: 0px;
  padding-right: 1.5em;
}
body.rtl_mode .sidebar.sidebar-mini .sidebar-mini-btn span {
  margin-right: 0px !important;
}
body.rtl_mode
  .sidebar.sidebar-mini
  .sidebar-mini-btn
  .icofont-bubble-right:before {
  content: "\ea64";
}
body.rtl_mode .sidebar.sidebar-mini .menu-list .m-link:hover span {
  left: unset;
  right: 101px;
}
body.rtl_mode .sidebar.sidebar-mini .menu-list .sub-menu {
  left: unset;
  right: 101px;
}
body.rtl_mode .sidebar.sidebar-mini .menu-list .sub-menu li a {
  justify-content: flex-end;
}
body.rtl_mode .sidebar.sidebar-mini .brand-icon .logo-text {
  padding-left: 0px;
  padding-right: 0px;
}
body.rtl_mode .sidebar .form-switch.theme-rtl {
  padding-left: 0px;
  padding-right: 0.8em;
}
body.rtl_mode .sidebar .sidebar-mini-btn span {
  margin-right: 0px !important;
}
body.rtl_mode .sidebar .sidebar-mini-btn .icofont-bubble-right:before {
  content: "\ea65";
}
body.rtl_mode .sidebar .brand-icon .logo-text {
  padding-left: 0px;
  padding-right: 15px;
}
body.rtl_mode .sidebar.open {
  left: unset;
  right: 0;
  margin-right: 0px !important;
}
body.rtl_mode .sidebar .list-unstyled {
  padding-right: 0px;
}
body.rtl_mode .sidebar .sub-menu {
  padding-right: 0px;
}
body.rtl_mode .rightchatbox .friend .status {
  left: 17px;
}
body.rtl_mode .icofont-hand-drawn-right:before {
  content: "\ea87";
}
body.rtl_mode .todo_list li label:before {
  left: unset;
  right: 0px;
}
body.rtl_mode .rightchatbox #close {
  left: unset;
  right: 10px;
}
body.rtl_mode .fc-media-screen.fc-direction-ltr {
  flex-direction: rtl;
  text-align: right;
}
body.rtl_mode .fc-media-screen.fc-direction-ltr .fc-toolbar {
  flex-direction: row-reverse;
}
body.rtl_mode .dropdown-item .me-2 {
  margin-left: 0px !important;
  margin-right: 0.5rem !important;
}
body.rtl_mode .dropdown-item .me-3 {
  margin-left: 0px !important;
  margin-right: 1rem !important;
}
body.rtl_mode .dropdown-menu-sm-end[data-bs-popper],
body.rtl_mode .dropdown-menu-end {
  right: auto !important;
  left: 0 !important;
}
body.rtl_mode .dropdown-menu-end {
  --bs-position: start;
}
body.rtl_mode .icofont-ui-add.me-2,
body.rtl_mode .icofont-plus-circle.me-2 {
  margin-left: 0px !important;
  margin-right: 0.5rem !important;
}
body.rtl_mode .btn-postion {
  right: unset;
  left: 15px;
}
body.rtl_mode .icofont-dotted-right:before {
  content: "\ea78";
}
body.rtl_mode .list-group i.me-3 {
  margin-left: 0px !important;
  margin-right: 1rem !important;
}
body.rtl_mode button i.me-2 {
  margin-left: 0px !important;
  margin-right: 0.5rem !important;
}
body.rtl_mode .card-chat.open {
  left: unset;
  right: 0;
}
body.rtl_mode .side-navbar {
  padding-left: 0;
  padding-right: 2rem;
}
body.rtl_mode .side-navbar ul {
  padding-left: 0;
  padding-right: 2rem;
}
body.rtl_mode .doc .me-2 {
  margin-left: 0px !important;
  margin-right: 0.5rem !important;
}
body.rtl_mode .doc ul {
  padding-left: 0;
  padding-right: 2rem;
}
body.rtl_mode .card.card-callout {
  border-left-width: 1px !important;
  border-left-color: var(--border-color);
  border-right-color: var(--primary-color);
  border-right-width: 0.4rem !important;
}
body.rtl_mode div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0px;
  margin-right: 0.5em;
}
body.rtl_mode .teacher-card .me-2 {
  margin-right: 0.5rem !important;
}
@media only screen and (max-width: 554px) {
  body.rtl_mode .project-tab .prtab-set {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
body.rtl_mode
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child,
body.rtl_mode
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child {
  padding-right: 30px;
  padding-left: 0px;
}
body.rtl_mode
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
body.rtl_mode
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  left: unset;
  right: 4px;
}
.border,
.border-top,
.border-right,
.border-bottom,
.border-left,
.dropdown-divider {
  border-color: var(--border-color);
}
.container-xxl {
  max-width: 1560px;
}
.font-weight-bold {
  font-weight: bold;
}
.table > :not(caption) > * > * {
  padding: 0.8rem 0.8rem;
}
.zindex-fixed {
  z-index: 1030;
}
.zindex-popover {
  z-index: 1070;
}
.zindex-modal {
  z-index: 1055;
}
.small-xs {
  font-size: 13px !important;
}
.form-control,
.form-select {
  border-color: var(--border-color);
  background-color: var(--color-200);
  color: var(--text-color);
  font-size: 16px;
}
.form-control::placeholder,
.form-select::placeholder {
  color: var(--color-400);
}
.form-control:focus,
.form-select:focus {
  box-shadow: 0 0 10px rgba(33, 37, 41, 0.15);
  background-color: var(--card-color);
  border-color: var(--primary-color);
  color: var(--text-color);
}
.form-control.form-control-lg,
.form-select.form-control-lg {
  min-height: calc(1.5em + 1.8rem + 2px);
}
.input-group-text {
  border-color: var(--border-color);
  background-color: var(--color-200);
  color: var(--text-color);
}
.form-switch .form-check-input {
  margin-left: -2em;
}
.form-switch .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--border-color);
}
.big-search {
  color: var(--color-fff);
}
.big-search:focus {
  color: var(--color-fff);
}
.btn-link {
  color: var(--text-color);
}
.btn-link:hover {
  color: var(--secondary-color);
}
.bg-white {
  color: var(--text-color);
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
  line-height: calc(1.375rem + 1.5vw) !important;
}
.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
  line-height: calc(1.325rem + 0.9vw) !important;
}
.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
  line-height: calc(1.3rem + 0.6vw) !important;
}
.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
  line-height: calc(1.275rem + 0.3vw) !important;
}
.btn {
  font-size: 14px;
  color: var(--text-color);
}
.btn:hover {
  color: var(--text-color);
}
.btn.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
}
.btn.btn-primary.active {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.btn.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--primary-color);
}
.btn.btn-outline-primary.active {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.btn.btn-outline-secondary {
  border-color: var(--border-color);
}
.btn.btn-outline-secondary:hover {
  color: #fff;
  background-color: var(--secondary-color);
}
.btn.btn-outline-secondary:hover i {
  color: #fff !important;
}
.btn.btn-outline-success {
  color: #4dca88;
  border-color: #4dca88;
}
.btn.btn-outline-success:hover {
  background-color: #4dca88;
  color: #fff;
}
.btn.btn-outline-danger {
  color: #fc5a69;
  border-color: #fc5a69;
}
.btn.btn-outline-danger:hover {
  background-color: #fc5a69;
  color: #fff;
}
.btn.btn-outline-info {
  color: #4fb8c9;
  border-color: #4fb8c9;
}
.btn.btn-outline-info:hover {
  background-color: #4fb8c9;
  color: #fff;
}
.btn.btn-white {
  background-color: var(--color-fff);
  border-color: var(--color-fff);
  color: var(--color-700);
}
.btn.btn-dark {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
}
.btn.btn-secondary {
  color: var(--white-color);
}
.btn.btn-lg,
.btn-group-lg > .btn {
  padding: 0.7rem 1rem;
}
.btn-close {
  color: var(--text-color);
}
.avatar {
  width: 34px;
  min-width: 34px;
  height: 34px;
}
.avatar.xs {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.avatar.sm {
  width: 22px !important;
  min-width: 22px !important;
  height: 22px !important;
}
.avatar.sm i {
  font-size: 12px;
  line-height: 12px;
  vertical-align: text-top;
}
.avatar.lg {
  width: 56px;
  min-width: 56px;
  height: 56px;
}
.avatar.xl {
  width: 120px;
  min-width: 120px;
  height: 120px;
}
.avatar.no-thumbnail {
  background-color: var(--color-300);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-list {
  margin: 0 0 -0.5rem;
  padding: 0;
  font-size: 0;
}
.avatar-list .avatar {
  color: var(--secondary-color);
  font-weight: 600;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  display: inline-block;
  background: var(--primary-color) no-repeat center/cover;
  position: relative;
  vertical-align: bottom;
  font-size: 0.875rem;
  user-select: none;
  min-width: 2rem;
}
.avatar-list .avatar:not(:last-child) {
  margin-right: 0.5rem;
}
.avatar-list.list-inlineblock li {
  display: inline-block;
}
.avatar-list-stacked .avatar {
  margin-right: -0.8em !important;
  box-shadow: 0 0 0 2px #fff;
}
@media only screen and (max-width: 490px) {
  .header .avatar-list-stacked {
    display: none;
  }
}
@media only screen and (min-width: 490px) {
  .add-member-top {
    display: none;
  }
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: rgba(33, 37, 41, 0.1);
}
.dropdown-item {
  color: var(--text-color);
}
.dropdown-item:hover {
  color: var(--secondary-color);
}
.dropdown-menu {
  font-size: 15px;
  color: var(--text-color);
  background-color: var(--body-color);
}
.dropdown-menu li {
  margin: 5px 0px;
}
.dropdown-menu .dropdown-item {
  color: var(--text-color);
}
.dropdown-menu .dropdown-item.active {
  color: var(--secondary-color);
  background-color: var(--primary-color);
}
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}
.dropdown-menu .dropdown-item:hover {
  color: var(--secondary-color);
}
.modal {
  right: 0;
  left: auto;
  z-index: 99999;
}
.modal.fade .modal-dialog-vertical {
  transform: translateX(-100%);
}
.modal.show .modal-dialog-vertical {
  transform: translateX(0);
}
.modal .modal-dialog-vertical {
  margin: 0;
  height: 100%;
}
.modal .modal-dialog-vertical .modal-content {
  height: 100%;
}
.modal .modal-header,
.modal .modal-footer {
  border-color: var(--border-color);
}
.modal .modal-content {
  background-color: var(--body-color);
}
.breadcrumb {
  background-color: var(--color-200);
}
.navbar-light .navbar-nav .nav-link {
  color: var(--color-500);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: var(--primary-color);
}
.nav-tabs {
  border-color: var(--border-color);
}
.nav-tabs.tab-custom {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.nav-tabs.tab-custom .nav-link {
  color: #fff;
  opacity: 0.7;
}
.nav-tabs.tab-custom .nav-link.active {
  opacity: 1;
  color: var(--primary-color);
}
.nav-tabs.tab-card .nav-link {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  color: var(--color-500);
}
.nav-tabs.tab-card .nav-link.active {
  border-color: var(--primary-color);
}
.nav-tabs.tab-body-header {
  border: 1px solid Var(--primary-color);
  overflow: hidden;
}
.nav-tabs.tab-body-header .nav-link {
  border: 0;
  margin: 0;
  border-radius: 0;
  color: var(--color-500);
}
.nav-tabs.tab-body-header .nav-link:hover {
  color: var(--primary-color);
}
.nav-tabs.tab-body-header .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
}
.nav-link {
  padding: 0.5rem 1rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary-color);
}
.nav-pills .nav-link {
  color: var(--text-color);
}
.border-dark {
  border-color: var(--text-color) !important;
}
.border-start {
  border-color: var(--border-color) !important;
}
.list-group-custom .list-group-item,
.list-group .list-group-item {
  background-color: var(--card-color);
  border-color: var(--border-color);
  color: var(--text-color);
}
.list-group-custom .list-group-item.active,
.list-group .list-group-item.active {
  background-color: var(--primary-color);
}
.progress {
  background-color: var(--color-100);
}
.progress.plan-active {
  background-color: var(--color-300);
}
.table {
  border-color: var(--border-color);
}
.table tr {
  vertical-align: middle;
}
.table tr th {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 12px;
}
.table tr td {
  border-color: var(--border-color);
  color: var(--text-color);
}
.table tr:hover td {
  color: var(--color-800);
}
.table .table-dark tr th {
  color: var(--white-color);
}
.table.doc-table tr th {
  border-color: var(--border-color);
}
.page-item.disabled .page-link {
  background-color: var(--border-color);
  border-color: var(--border-color);
}
.page-item .page-link {
  background-color: var(--card-color);
  border-color: var(--border-color);
  color: var(--primary-color);
}
.page-item.active .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.no-resize {
  resize: none;
}
.text-primary {
  color: var(--primary-color) !important;
}
.bg-primary {
  background: var(--primary-color) !important;
}
.fill-primary {
  fill: var(--primary-color) !important;
}
.text-secondary {
  color: var(--secondary-color) !important;
}
.bg-secondary {
  background: var(--secondary-color) !important;
}
.bg-primary-gradient {
  background: var(--primary-gradient) !important;
  color: #fff;
}
.color-defult {
  color: #212529 !important;
}
.text-muted {
  color: #9a9b9d !important;
}
.link-secondary {
  color: var(--secondary-color) !important;
}
.btn-success {
  background-color: #4dca88;
  border-color: #4dca88;
}
.btn-danger {
  background-color: #fc5a69;
  border-color: #fc5a69;
}
.btn-warning {
  background-color: #ffba33;
  border-color: #ffba33;
}
.btn-info {
  background-color: #4fb8c9;
  border-color: #4fb8c9;
}
.border,
.border-left,
.border-top,
.border-right,
.border-bottom {
  border-color: var(--color-100) !important;
}
.vh100 {
  height: 100vh;
}
.w30 {
  width: 30px;
}
.w60 {
  width: 60px;
}
.w90 {
  width: 90px;
}
.w120 {
  width: 120px;
}
.w220 {
  width: 220px;
}
.w240 {
  width: 240px;
}
.w280 {
  width: 280px;
}
.w380 {
  width: 380px;
}
.w-30 {
  width: 30%;
}
.min-height-220 {
  min-height: 220px;
}
.color-fff {
  color: var(--color-fff);
}
.color-100 {
  color: var(--color-100);
}
.color-200 {
  color: var(--color-200);
}
.color-300 {
  color: var(--color-300);
}
.color-400 {
  color: var(--color-400);
}
.color-600 {
  color: var(--color-600);
}
.color-700 {
  color: var(--color-700);
}
.color-800 {
  color: var(--color-800);
}
.color-900 {
  color: var(--color-900);
}
.color-000 {
  color: var(--color-000);
}
.color-defult-white {
  color: #fff;
}
.color-bg-fff {
  background-color: var(--color-fff) !important;
}
.color-bg-100 {
  background-color: var(--color-100) !important;
}
.color-bg-200 {
  background-color: var(--color-200) !important;
}
.color-bg-300 {
  background-color: var(--color-300) !important;
}
.color-bg-400 {
  background-color: var(--color-400) !important;
}
.color-bg-600 {
  background-color: var(--color-600) !important;
}
.color-bg-700 {
  background-color: var(--color-700) !important;
}
.color-bg-800 {
  background-color: var(--color-800) !important;
}
.color-bg-900 {
  background-color: var(--color-900) !important;
}
.color-bg-000 {
  background-color: var(--color-000) !important;
}
.bg-dark {
  background-color: var(--primary-color) !important;
}
.bg-dark-defualt {
  background-color: #212529 !important;
}
.bg-lightyellow {
  background-color: #ffe28c !important;
}
.bg-lightblue {
  background-color: #a7daff !important;
}
.bg-lightgreen {
  background-color: #a8d7e0 !important;
}
.light-warning-bg {
  background-color: #fff4de !important;
}
.light-primary-bg {
  background-color: #e1f0ff !important;
}
.light-info-bg {
  background-color: #eee5ff !important;
}
.light-danger-bg {
  background-color: #f1c8db !important;
}
.light-success-bg {
  background-color: #a0d9b4 !important;
}
.light-orange-bg {
  background-color: #ffaa8a !important;
}
.bg-info-light {
  background-color: #9ae8ff !important;
}
.bg-santa-fe {
  background-color: #b87c4c !important;
}
.bg-careys-pink {
  background-color: #cf9eac !important;
}
.bg-lavender-purple {
  background-color: #a883ba !important;
}
.bg-video {
  background-color: #1a1e2b !important;
}
.color-lightyellow {
  color: #ffe28c !important;
}
.color-lightblue {
  color: #a7daff !important;
}
.color-lightgreen {
  color: #a8d7e0 !important;
}
.color-light-success {
  color: #a0d9b4 !important;
}
.color-light-orange {
  color: #ffaa8a !important;
}
.color-info-light {
  color: #9ae8ff !important;
}
.color-santa-fe {
  color: #b87c4c !important;
}
.color-careys-pink {
  color: #cf9eac !important;
}
.color-lavender-purple {
  color: #a883ba !important;
}
.color-danger {
  color: #f1c8db !important;
}
.img-overlay:before {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.line-lightyellow {
  position: relative;
}
.line-lightyellow:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #ffe28c !important;
}
.line-lightblue {
  position: relative;
}
.line-lightblue:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #a7daff !important;
}
.line-lightgreen {
  position: relative;
}
.line-lightgreen:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #a8d7e0 !important;
}
.line-light-success {
  position: relative;
}
.line-light-success:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #a0d9b4 !important;
}
.line-light-orange {
  position: relative;
}
.line-light-orange:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #ffaa8a !important;
}
.line-info-light {
  position: relative;
}
.line-info-light:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #9ae8ff !important;
}
.line-santa-fe {
  position: relative;
}
.line-santa-fe:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #b87c4c !important;
}
.line-careys-pink {
  position: relative;
}
.line-careys-pink:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #cf9eac !important;
}
.line-lavender-purple {
  position: relative;
}
.line-lavender-purple:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 5px;
  height: 100%;
  background-color: #a883ba !important;
}
.line-height-custom {
  line-height: 24px;
}
.line-height-sm {
  line-height: 15px;
}
.small-14 {
  font-size: 14px;
}
.small-11 {
  font-size: 11px;
}
.btn-postion {
  position: absolute;
  right: 15px;
  top: -50px;
}
@media only screen and (max-width: 640px) {
  .btn-postion {
    position: unset;
    right: unset;
    top: unset;
  }
}
.no-bg {
  background-color: transparent !important;
}
.pointer {
  cursor: pointer;
}
.chooser {
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 100%;
}
.dividers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.dividers:after,
.dividers:before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid var(--border-color);
  content: "";
  margin-top: 0.0625rem;
}
.dividers::before {
  margin-right: 1.5rem;
}
.dividers::after {
  margin-left: 1.5rem;
}
.dividers-block {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
.dividers-block:not([size]) {
  height: 1px;
}
.task_status span {
  width: 15px;
  height: 15px;
  display: block;
}
[data-theme="dark"] .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.15) !important;
}
[data-theme="dark"] .shadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.15) !important;
}
[data-theme="dark"] .text-muted {
  color: #5b5b5b !important;
}
[data-theme="dark"] .table .table-dark {
  --bs-table-bg: var(--primary-color);
}
[data-theme="dark"] .table .table-dark tr th {
  color: var(--text-color);
  background-color: #313131;
}
[data-theme="dark"] .light-warning-bg {
  background-color: #ead09d !important;
}
[data-theme="dark"] .big-search {
  color: var(--text-color);
}
[data-theme="dark"] .big-search:focus {
  color: var(--text-color);
}
[data-theme="dark"] .timeline-item {
  border-left: 2px solid var(--border-color) !important;
}
[data-theme="dark"] .timeline-item .avatar {
  color: var(--border-color);
}
[data-theme="dark"] .border,
[data-theme="dark"] .border-left,
[data-theme="dark"] .border-top,
[data-theme="dark"] .border-right,
[data-theme="dark"] .border-bottom {
  border-color: var(--border-color) !important;
}
[data-theme="dark"] .table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--border-color);
}
[data-theme="dark"] .bg-lightyellow,
[data-theme="dark"] .bg-lightblue,
[data-theme="dark"] .bg-lightgreen,
[data-theme="dark"] .light-warning-bg,
[data-theme="dark"] .light-primary-bg,
[data-theme="dark"] .light-info-bg,
[data-theme="dark"] .light-danger-bg,
[data-theme="dark"] .light-success-bg,
[data-theme="dark"] .light-orange-bg,
[data-theme="dark"] .bg-info-light,
[data-theme="dark"] .bg-santa-fe,
[data-theme="dark"] .bg-careys-pink,
[data-theme="dark"] .bg-lavender-purple {
  color: var(--body-color);
}
[data-theme="dark"] .top-perfomer h6,
[data-theme="dark"] .top-perfomer .h6 {
  color: var(--text-color);
}
.dropdown-animation {
  opacity: 0;
  transform: translateY(15px);
  -webkit-animation: eliment-animation 0.2s ease-in-out 0.33333s;
  animation: eliment-animation 0.2s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
@-webkit-keyframes eliment-animation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes eliment-animation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}
.lift:focus,
.lift:hover {
  box-shadow: 0 6px 1rem rgba(33, 37, 41, 0.1),
    0 0.5rem 1rem -0.75rem rgba(33, 37, 41, 0.1) !important;
  transform: translate3d(0, -3px, 0);
}
#mytask-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navigation {
  background-color: var(--card-color);
  width: 60px;
  height: 100vh;
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  z-index: 9;
}
@media only screen and (max-width: 991px) {
  .navigation {
    width: 100%;
    height: 60px;
    flex-direction: row;
    align-items: center;
    position: fixed;
    overflow: hidden;
  }
  .navigation .brand-icon svg {
    width: 22px;
    margin-right: 20px;
  }
  .navigation .navbar-nav {
    justify-content: flex-end !important;
    padding-right: 50px;
    flex-direction: row !important;
  }
  .navigation .navbar-nav .flex-grow-1 {
    flex-grow: unset !important;
  }
  .navigation .navbar-nav .nav-link {
    margin-left: 5px;
  }
  .navigation .navbar-nav .menu-toggle {
    position: fixed;
    right: 6px;
  }
}
.sidebar {
  -moz-transition: ease 0.1s;
  -o-transition: ease 0.1s;
  -webkit-transition: ease 0.1s;
  transition: ease 0.1s;
  background: var(--primary-color);
  z-index: 99999;
  margin: 25px;
  height: calc(100vh - 50px);
  width: 260px;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-radius: 1.1rem;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media only screen and (max-width: 1279px) {
  .sidebar {
    position: fixed;
    left: -400px;
    height: 100vh;
    margin: 0px;
    border-radius: 0px;
  }
}
@media only screen and (min-width: 1279px) {
  .sidebar {
    overflow-y: auto;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: auto;
  }
  .sidebar::-webkit-scrollbar {
    width: 0;
  }
}
@media only screen and (max-width: 375px) {
  .sidebar {
    height: 100%;
  }
}
.sidebar .form-switch label {
  color: var(--white-color);
  font-size: 15px;
  padding-left: 10px;
}
.sidebar .form-switch.theme-rtl {
  padding-left: 2em;
}
.sidebar .sidebar-title {
  color: var(--white-color);
}
.sidebar .menu-list {
  list-style: none;
  padding: 0;
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sidebar .menu-list::-webkit-scrollbar {
  width: 4px;
}
.sidebar .menu-list:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.sidebar .menu-list:hover::-webkit-scrollbar-track {
  visibility: visible;
}
.sidebar .menu-list::-webkit-scrollbar-track {
  visibility: hidden;
  background: transparent;
}
.sidebar .menu-list::-webkit-scrollbar-thumb {
  visibility: hidden;
  opacity: 0.2;
  background: var(--secondary-color);
  border-radius: 5px;
}
.sidebar .menu-list::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}
.sidebar .menu-list li[aria-expanded="true"] .m-link {
  color: var(--secondary-color);
}
.sidebar .menu-list li[aria-expanded="true"] .arrow:before {
  content: "\f10c";
}
.sidebar .menu-list li[aria-expanded="true"] .sub-menu:before {
  background-color: var(--secondary-color);
}
.sidebar .menu-list .m-link,
.sidebar .menu-list .ms-link {
  display: flex;
}
.sidebar .menu-list .m-link:hover,
.sidebar .menu-list .m-link.active,
.sidebar .menu-list .ms-link:hover,
.sidebar .menu-list .ms-link.active {
  color: var(--secondary-color);
}
.sidebar .menu-list .m-link:hover::before,
.sidebar .menu-list .m-link.active::before,
.sidebar .menu-list .ms-link:hover::before,
.sidebar .menu-list .ms-link.active::before {
  display: block;
}
.sidebar .menu-list .m-link {
  color: var(--white-color);
  align-items: center;
  padding: 10px 0;
  font-size: 17px;
}
@media only screen and (max-width: 767px) {
  .sidebar .menu-list .m-link {
    font-size: 15px;
    padding: 8px 0;
  }
}
.sidebar .menu-list .ms-link {
  color: var(--white-color);
  position: relative;
  padding: 5px 0;
}
.sidebar .menu-list .ms-link span {
  font-size: 14px;
}
.sidebar .menu-list .fa {
  width: 30px;
}
.sidebar .menu-list .arrow {
  color: var(--white-color);
  opacity: 0.3;
  font-size: 10px;
}
.sidebar .menu-list .divider {
  color: var(--white-color);
  border-color: rgba(255, 255, 255, 0.3) !important;
  opacity: 0.5;
}
.sidebar .menu-list [class^="icofont-"] {
  width: 30px;
}
.sidebar .menu-list .sub-menu {
  list-style: none;
  position: relative;
  padding-left: 30px;
}
.sidebar .menu-list .sub-menu::before {
  background-color: var(--white-color);
  content: "";
  position: absolute;
  height: 100%;
  width: 0px;
  left: 8px;
  top: 0;
}
.sidebar .menu-list .sub-menu .ms-link::before {
  top: 10px;
}
.sidebar .sidebar-mini-btn {
  font-size: 34px;
}
.sidebar .sidebar-mini-btn:focus,
.sidebar .sidebar-mini-btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.sidebar .sidebar-mini-btn .icofont-bubble-right:before {
  content: "\ea64";
}
@media only screen and (max-width: 767px) {
  .sidebar .sidebar-mini-btn {
    display: none;
  }
}
.sidebar.gradient {
  background: var(--primary-gradient);
}
.sidebar.gradient.sidebar-mini .menu-list .sub-menu {
  background: var(--primary-gradient);
}
.sidebar.sidebar-mini {
  text-align: center;
  padding: 1.5rem 0 !important;
  width: 100px;
  z-index: 9999;
}
@media only screen and (min-width: 1279px) {
  .sidebar.sidebar-mini {
    overflow: visible;
  }
}
.sidebar.sidebar-mini .brand-icon {
  display: block;
}
.sidebar.sidebar-mini .brand-icon .logo-icon {
  margin: 0 auto;
}
.sidebar.sidebar-mini .brand-icon .logo-text {
  padding-left: 0px;
  padding-top: 8px;
}
.sidebar.sidebar-mini .sidebar-title span,
.sidebar.sidebar-mini form {
  display: none;
}
.sidebar.sidebar-mini .menu-list > li {
  position: relative;
}
.sidebar.sidebar-mini .menu-list > li:hover .sub-menu {
  display: block !important;
}
.sidebar.sidebar-mini .menu-list .m-link {
  margin-bottom: 10px;
}
.sidebar.sidebar-mini .menu-list .m-link span {
  display: none;
}
.sidebar.sidebar-mini .menu-list .m-link:hover span {
  display: block;
  background: var(--primary-color);
  border-radius: 0.25rem;
  box-shadow: 0 10px 40px 10px rgba(33, 37, 41, 0.2);
  width: 200px;
  padding: 10px 0px 10px 30px;
  text-align: left;
  position: absolute;
  left: 101px;
  top: 0;
  font-size: 17px;
}
.sidebar.sidebar-mini .menu-list .m-link:hover span.badge {
  font-size: 13px;
}
.sidebar.sidebar-mini .menu-list .m-link:hover span.badge:before {
  top: 12px;
}
.sidebar.sidebar-mini .menu-list .ms-link::before {
  left: -16px;
}
.sidebar.sidebar-mini .menu-list .ms-link span {
  font-size: 17px;
}
.sidebar.sidebar-mini .menu-list .fa {
  width: 100%;
}
.sidebar.sidebar-mini .menu-list .sub-menu {
  background: var(--primary-color);
  border-radius: 0.25rem;
  box-shadow: 0 10px 40px 10px rgba(33, 37, 41, 0.2);
  width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  left: 101px;
  top: 0;
}
.sidebar.sidebar-mini .menu-list .sub-menu:before {
  display: none;
}
.sidebar.sidebar-mini .menu-list .sub-menu .ms-link::before {
  top: 12px;
}
.sidebar.sidebar-mini .menu-list .divider small,
.sidebar.sidebar-mini .menu-list .divider .small {
  display: none;
}
.sidebar.sidebar-mini .menu-list [class^="icofont-"] {
  width: 100%;
}
.sidebar.sidebar-mini .fa-arrow-left::before {
  content: "\f061";
}
.sidebar.sidebar-mini #menu-Components {
  width: 300px;
}
.sidebar.sidebar-mini #menu-Components li {
  width: 50%;
  float: left;
}
.sidebar.sidebar-mini #menu-Authentication {
  width: 230px;
}
.sidebar.sidebar-mini .sub-menu.show {
  display: none;
}
.sidebar.sidebar-mini .sidebar-mini-btn .icofont-bubble-right:before {
  content: "\ea65";
}
.sidebar.sidebar-mini .form-switch label {
  display: none;
}
.sidebar.sidebar-mini .form-switch.theme-rtl {
  padding-left: 2.5em;
}
.sidebar.open {
  left: 0px;
}
@media only screen and (max-width: 991px) {
  .sidebar.open {
    left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sidebar .brand-icon .logo-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .sidebar .brand-icon .logo-icon svg {
    width: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .sidebar .brand-icon .logo-text {
    font-size: 17px;
  }
}
.menu-toggle {
  color: var(--text-color);
}
@media only screen and (min-width: 1280px) {
  .menu-toggle {
    display: none;
  }
}
.main {
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100vh;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.main::-webkit-scrollbar {
  width: 4px;
}
.main:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.main:hover::-webkit-scrollbar-track {
  visibility: visible;
}
.main::-webkit-scrollbar-track {
  visibility: hidden;
  background: transparent;
}
.main::-webkit-scrollbar-thumb {
  visibility: hidden;
  opacity: 0.2;
  background: var(--secondary-color);
  border-radius: 5px;
}
.main::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}
.main .body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
}
.auth-h100 {
  height: calc(100vh - 192px);
}
@media only screen and (max-width: 1280px) {
  .auth-h100 {
    height: calc(100vh - 96px);
  }
}
@media only screen and (max-width: 640px) {
  .auth-h100 {
    height: auto;
  }
}
.fc * {
  color: var(--primary-color);
}
.fc .fc-col-header-cell-cushion {
  color: var(--primary-color);
}
.fc .fc-h-event .fc-event-title {
  color: var(--white-color);
}
.fc .fc-h-event {
  background-color: var(--primary-color);
  border-color: var(--border-color);
}
.fc .fc-daygrid-event-dot {
  border-color: var(--secondary-color);
}
.fc .fc-button-primary {
  background-color: var(--secondary-color);
  color: var(--white-color);
  border-color: var(--secondary-color);
}
.fc .fc-button-primary .fc-icon {
  color: var(--white-color);
}
.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: var(--border-color);
}
.card-chat,
.card-chat-body {
  height: 100vh;
}
.chatlist-toggle {
  color: var(--primary-color);
}
.card-chat {
  min-width: 380px;
}
@media only screen and (max-width: 991px) {
  .card-chat {
    -moz-transition: ease 0.1s;
    -o-transition: ease 0.1s;
    -webkit-transition: ease 0.1s;
    transition: ease 0.1s;
    position: fixed;
    left: -600px;
    z-index: 9;
  }
  .card-chat.open {
    left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .card-chat.open {
    min-width: 300px;
    width: 300px;
  }
}
@media only screen and (max-width: 360px) {
  .card-chat.open {
    min-width: 260px;
    width: 260px;
  }
}
.card-chat .tab-content {
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
  height: calc(100vh - 150px);
}
.card-chat .tab-content::-webkit-scrollbar {
  width: 0;
}
.card-chat .list-group .list-group-item.open,
.card-chat .list-group .list-group-item:hover {
  background-color: var(--body-color);
}
.card-chat .list-group .list-group-item a {
  color: var(--color-500);
}
.card-chat .list-group .msg-time {
  font-size: 11px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .card-chat .nav-link {
    padding: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .card-chat-body {
    height: calc(100vh - 60px);
  }
}
.card-chat-body .max-width-70 {
  max-width: 70%;
}
@media only screen and (max-width: 768px) {
  .card-chat-body .max-width-70 {
    max-width: 100%;
  }
}
.card-chat-body .chat-history {
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100vh;
  overflow-y: auto;
}
.card-chat-body .chat-history::-webkit-scrollbar {
  width: 0;
}
.card-chat-body .text-right .message {
  color: #fff;
}
.card-chat-body .message {
  color: var(--color-600);
}
.card-chat-body.card {
  background-color: var(--body-color);
}
.timeline-item {
  border-left: 2px solid var(--border-color);
  display: flex;
  position: relative;
  padding: 1em;
}
.timeline-item:hover::after {
  transform: scale(3.5);
}
.timeline-item::after {
  -moz-transition: ease 0.1s;
  -o-transition: ease 0.1s;
  -webkit-transition: ease 0.1s;
  transition: ease 0.1s;
  border: 2px solid var(--border-color);
  background: var(--color-900);
  display: block;
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: 1.3em;
  left: -5px;
  border-radius: 30px;
}
.timeline-item.ti-primary:after {
  background: var(--primary-color);
}
.timeline-item.ti-info:after {
  background: #0dcaf0;
}
.timeline-item.ti-success:after {
  background: #7eb93f;
}
.timeline-item.ti-warning:after {
  background: #f8b500;
}
.timeline-item.ti-danger:after {
  background: #ff1843;
}
.dd-handle {
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border-radius: 0px;
  padding: 20px;
  position: relative;
  color: var(--text-color);
  border-color: var(--border-color);
  background-color: var(--card-color);
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}
.dd-handle:hover {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
}
.taskboard .dd-handle {
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border-radius: 0px;
  padding: 20px;
  position: relative;
  color: var(--text-color);
  border-color: var(--border-color);
  background-color: var(--card-color);
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}
.taskboard .dd-handle:hover {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
}
.taskboard .dd-item {
  border-color: var(--border-color);
  background-color: var(--card-color);
  position: relative;
  margin-bottom: 15px;
}
.taskboard .dd-item:last-child {
  margin-bottom: 0px;
}
.taskboard .dd-item:hover {
  cursor: move;
}
.taskboard .dd-item:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0px;
  bottom: 0px;
  z-index: 9;
}
@media only screen and (max-width: 1366px) {
  .taskboard .dd-item,
  .taskboard .dd-empty,
  .taskboard .dd-placeholder {
    font-size: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .taskboard .dd-item,
  .taskboard .dd-empty,
  .taskboard .dd-placeholder {
    font-size: 14px;
  }
}
.taskboard .dd-empty {
  border: 1px dashed var(--secondary-color);
  background-color: var(--secondary-color);
}
.taskboard .planned_task .dd-item:before {
  background-color: #cd201f;
}
.taskboard .progress_task .dd-item:before {
  background-color: #ffc107;
}
.taskboard .review_task .dd-item:before {
  background-color: #ffaa8a;
}
.taskboard .completed_task .dd-item:before {
  background-color: #28a745;
}
.taskboard .small-avt {
  width: 25px;
  height: 25px;
  min-width: 25px;
}
.client_task .dd-handle:hover {
  color: var(--text-color);
}
.todo-list li {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  padding: 14px 0;
  font-size: 1rem;
}
.todo-list li:last-child {
  border-bottom: 0;
}
.todo-list li .fa-check {
  color: var(--color-400);
}
.todo-list li.active > span {
  color: var(--primary-color);
  text-decoration: line-through;
}
.todo-list li.active .fa-check {
  color: var(--primary-color);
}
.print_invoice table {
  border-collapse: collapse;
}
.print_invoice table td,
.print_invoice table th {
  border: 1px solid var(--border-color);
  padding: 5px;
}
.print_invoice textarea {
  border: 1px solid var(--card-color);
  overflow: hidden;
  resize: none;
  width: 100%;
  border-radius: 0.25rem;
}
.print_invoice textarea:hover,
.print_invoice textarea:focus {
  background: rgba(var(--primary-rgb), 0.1);
  border: 1px solid var(--primary-color);
  box-shadow: none;
  outline: 0;
}
.print_invoice .address {
  float: left;
  width: 250px;
  height: 150px;
}
.print_invoice .customer {
  overflow: hidden;
}
.print_invoice .customer .customer-title {
  float: left;
  width: 250px;
  font-size: 20px;
  font-weight: 700;
}
.print_invoice .delete-wpr {
  position: relative;
}
.print_invoice .delete {
  background: var(--border-color);
  color: #fc5a69;
  display: block;
  text-decoration: none;
  position: absolute;
  padding: 0 5px;
  top: 3px;
  left: -22px;
  font-size: 12px;
}
.print_invoice .delete:hover {
  background: #fc5a69;
  color: #fff;
}
.print_invoice #logo {
  text-align: right;
  float: right;
  position: relative;
  overflow: hidden;
  margin-top: 25px;
  max-width: 540px;
  max-height: 100px;
}
.print_invoice #logo:hover,
.print_invoice #logo.edit {
  border: 1px solid var(--border-color);
  margin-top: 0;
  max-height: 125px;
}
.print_invoice #logo:hover #logoctr,
.print_invoice #logo.edit #logoctr {
  background: var(--color-100);
  display: block;
  text-align: right;
  line-height: 25px;
  padding: 0 5px;
}
.print_invoice #logo #logoctr {
  display: none;
}
.print_invoice #logohelp {
  text-align: left;
  display: none;
  font-style: italic;
  padding: 10px 5px;
}
.print_invoice #logohelp input {
  margin-bottom: 5px;
}
.print_invoice .edit #logohelp {
  display: block;
}
.print_invoice .edit #save-logo,
.print_invoice .edit #cancel-logo {
  display: inline;
}
.print_invoice .edit #image,
.print_invoice #save-logo,
.print_invoice #cancel-logo,
.print_invoice .edit #change-logo,
.print_invoice .edit #delete-logo {
  display: none;
}
.print_invoice .meta {
  float: right;
  margin-top: 1px;
  width: 300px;
}
.print_invoice .meta td {
  text-align: right;
}
.print_invoice .meta td.meta-head {
  background: var(--color-100);
  text-align: left;
}
.print_invoice .meta td .due {
  padding-right: 13px;
}
.print_invoice .meta td textarea {
  height: 20px;
  text-align: right;
}
.print_invoice .items {
  border: 1px solid var(--border-color);
  clear: both;
  width: 100%;
  margin: 30px 0 0;
}
.print_invoice .items th {
  background: var(--color-100);
}
.print_invoice .items tr.item-row td {
  vertical-align: top;
}
.print_invoice .items td.item-name {
  width: 150px;
}
.print_invoice .items td.description {
  width: 280px;
}
@media only screen and (max-width: 992px) {
  .print_invoice .items td.description {
    width: 220px;
  }
}
.print_invoice .items td.total-line {
  text-align: right;
  border-right: 0;
}
.print_invoice .items td.total-value {
  padding: 5px;
}
.print_invoice .items td.balance {
  background: var(--color-100);
}
.print_invoice .items td.blank {
  border: 0;
}
@media print {
  body * {
    visibility: hidden;
    margin: 0;
    color: #000;
    background-color: #fff;
  }
  .print_invoice {
    position: absolute;
    left: 0;
    top: 0;
  }
  .print_invoice * {
    visibility: visible;
  }
  .print_invoice * #hiderow,
  .print_invoice * .delete {
    display: none;
  }
  .print_invoice .footer-note .form-control {
    padding: 0;
  }
  .navigation,
  .sidebar {
    display: none;
  }
}
.card {
  border-color: var(--border-color);
  background-color: var(--card-color);
}
.card.card-callout {
  border-left-width: 0.4rem;
  border-left-color: var(--primary-color);
}
.card .card-header {
  background-color: transparent;
  border-bottom: none;
}
.bg-dark .text-muted {
    color: #a8a8a8 !important;
}