
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.521);
  backdrop-filter: blur(35px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#preloader div {
  width: 8vmax;
  height: 8vmax;
  border-right: 4px solid #000;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
}
#preloader div:before,
#preloader div:after {
  content: "";
  width: 6vmax;
  height: 6vmax;
  display: block;
  position: absolute;
  top: calc(50% - 3vmax);
  left: calc(50% - 3vmax);
  border-left: 3px solid #000;
  border-radius: 100%;
  animation: spinLeft 800ms linear infinite;
}
#preloader div:after {
  width: 4vmax;
  height: 4vmax;
  top: calc(50% - 2vmax);
  left: calc(50% - 2vmax);
  border: 0;
  border-right: 2px solid #000;
  animation: none;
}
@keyframes spinLeft {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(720deg);
  }
}
@keyframes spinRight {
  from {
      transform: rotate(360deg);
  }
  to {
      transform: rotate(0deg);
  }
}